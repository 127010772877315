import { DateRange, DateRangePicker, DateRangePickerProps } from 'bold-ui'
import { isEqual as isEqualDateFns } from 'date-fns'
import React from 'react'
import { toDate } from 'util/date/formatDate'

import { UseFieldProps, usePecField } from '../final-form/hooks/useField'
import { getFieldError } from '../final-form/util'

export interface LocalDateRange {
  startDate?: LocalDate
  endDate?: LocalDate
}

type FieldType = LocalDateRange | DateRange

function isLocalDateRange(range: FieldType): range is LocalDateRange {
  return typeof range?.startDate === 'string' && typeof range?.endDate === 'string'
}

function isDateRange(range: FieldType): range is DateRange {
  return typeof range?.startDate === 'object' && typeof range?.endDate === 'object'
}

/**
 * Essa função foi criada devido ao fato do final-form usar o Shallow Equal
 * para verificar se um campo é igual ao outro para computar o valor de 'dirty'
 * e também compara com os initial values para computar o 'pristine'
 * Ref: https://final-form.org/docs/react-final-form/types/FieldProps#isequal
 * Como no caso das datas é sempre criado um objeto novo, o campo é considerado 'dirty' ou 'not pristine' mesmo que os ranges
 * tenham valores iguais por causa do Shallow Equal
 */
const isEqual = (dateA: FieldType, dateB: FieldType): boolean => {
  if (isLocalDateRange(dateA) && isLocalDateRange(dateB)) {
    return (
      isEqualDateFns(toDate(dateA?.startDate), toDate(dateB?.startDate)) &&
      isEqualDateFns(toDate(dateA?.endDate), toDate(dateB?.endDate))
    )
  }

  if (isDateRange(dateA) && isDateRange(dateB)) {
    return isEqualDateFns(dateA?.startDate, dateB?.startDate) && isEqualDateFns(dateA?.endDate, dateB?.endDate)
  }
  return false
}

export type DateRangeFieldProps = UseFieldProps<FieldType> & Omit<DateRangePickerProps, 'name'>

export default function DateRangeField(props: DateRangeFieldProps) {
  const { input, meta, ...rest } = usePecField({
    ...props,
    isEqual,
  })
  return <DateRangePicker error={getFieldError(meta)} {...input} {...rest} />
}
