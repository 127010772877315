import { Modal } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useModeloPersonalizadoRelatorioGerencialQuery } from 'graphql/hooks.generated'
import { TipoModuloEnum } from 'graphql/types.generated'
import React, { useCallback, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { KeyMapping } from '../../../common/keyMapping-relatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialForm } from './ModeloPersonalizadoRelatorioGerencialForm'

export const MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL = '/modelo-personalizado'

interface UrlParams {
  id: ID
}

interface ModeloPersonalizadoRelatorioGerencialModalProps {
  modulo: TipoModuloEnum
  keyMapping: Map<string, KeyMapping>
}

export function ModeloPersonalizadoRelatorioGerencialModal(props: ModeloPersonalizadoRelatorioGerencialModalProps) {
  const { modulo, keyMapping } = props
  const { url } = useRouteMatch()
  const history = useHistory()
  const alert = useAlert()
  const { id } = useParams<UrlParams>()

  const urlToReplace = MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL + (id ? '/' + id : '')
  const handleClose = useCallback(() => {
    history.push(url.replace(urlToReplace, ''))
  }, [history, url, urlToReplace])
  const isCadastro = isUndefinedOrNull(id)

  const { data, loading } = useModeloPersonalizadoRelatorioGerencialQuery({ variables: { id }, skip: isCadastro })

  useEffect(() => {
    const queryReturnedNull =
      !isCadastro && !loading && isUndefinedOrNull(data?.modeloPersonalizadoRelatorioGerencialById)
    if (queryReturnedNull) {
      alert('danger', 'Você não tem permissão para editar este modelo personalizado')
      handleClose()
    } else if (isUndefinedOrNull(keyMapping)) {
      alert('danger', 'Não existe modelo personalizado para esse tipo de relatório')
      handleClose()
    }
  }, [alert, data, handleClose, id, isCadastro, keyMapping, loading])

  const tituloModal = (isCadastro ? 'Cadastrar' : 'Editar') + ' modelo de relatório'
  return (
    <Modal open={!loading} onClose={handleClose}>
      <ModeloPersonalizadoRelatorioGerencialForm
        keyMapping={keyMapping}
        editValues={data?.modeloPersonalizadoRelatorioGerencialById}
        tipoModulo={modulo}
        tituloModal={tituloModal}
        handleCloseModal={handleClose}
        isCadastro={isCadastro}
      />
    </Modal>
  )
}
