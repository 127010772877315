import { Alert, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PageContent } from 'components/layout/PageContent'
import { CuidadoCompartilhadoQueryFilterEnum, CuidadosCompartilhadoQueryInput } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import React from 'react'

import {
  CuidadoCompartilhadoSort,
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
} from '../model-cuidadoCompartilhado'
import { ListaCuidadoCompartilhadoHeader } from './ListaCuidadoCompartilhadoHeader'
import { ListaCuidadoCompartilhadoView } from './ListaCuidadoCompartilhadoView'

const filterToQueryInput = (
  value: ListaCuidadoCompartilhadoFilterModel,
  unidadeSaudeId?: ID
): CuidadosCompartilhadoQueryInput => {
  return {
    query: value.query,
    status: value.status,
    dataInicio: value.periodo?.startDate,
    dataFim: value.periodo?.endDate,
    executadosPorProfissionalId: value.profissionalExecutante?.profissional?.id,
    solicitadosPorProfissionalId: value.profissionalSolicitante?.profissional?.id,
    unidadeSaudeId: unidadeSaudeId,
    equipesIds: isEmpty(value.equipe) ? null : value.equipe?.map((item) => item.id),
    pageParams: { ...value.pageParams, sort: CuidadoCompartilhadoSort[value.pageParams.sort] },
  }
}

const defaultSort = [
  CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE,
  CuidadoCompartilhadoSortEnum.STATUS_SOLICITANTE,
]

export const ListaCuidadoCompartilhadoGerenteView = () => {
  const { acesso } = useAcessoLotacaoOrEstagio()
  const filterToQuery = (value: ListaCuidadoCompartilhadoFilterModel) =>
    filterToQueryInput(value, acesso.unidadeSaude.id)

  return (
    <>
      <ListaCuidadoCompartilhadoHeader isGerente={true} />
      <PageContent fluid type='transparent'>
        <VFlow vSpacing={0.5}>
          <Alert type='info' inline>
            A lista exibe todas as discussões desta unidade.
          </Alert>
          <ListaCuidadoCompartilhadoView
            defaultSort={CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE}
            itemsToSort={defaultSort}
            filterToQueryInput={filterToQuery}
            role={CuidadoCompartilhadoQueryFilterEnum.GERENTE}
          />
        </VFlow>
      </PageContent>
    </>
  )
}
