import {
  CiapCidModel,
  CiapCidSelectModel,
  isCiap,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField'
import {
  GrupoCondicaoEnum,
  OpcaoSelecionadaRelatorioGerencialEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'
import { convertDateRangeToInstantRange, convertInstantRangeToDateRange } from 'util/date/dateRange'
import { toDate } from 'util/date/formatDate'

import {
  RelatorioGerencialFiltrosStorageModel,
  RelatorioGerencialModeloPersonalizado,
} from '../../common/model-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from './RelatorioGerencialFiltrosForm'

export const convertRelatorioGerencialStorageModelToFormModel = (
  storageModel: RelatorioGerencialFiltrosStorageModel
): RelatorioGerencialFiltrosFormModel => {
  if (storageModel) {
    const { periodo, ...rest } = storageModel
    return {
      ...rest,
      periodo: periodo ? convertInstantRangeToDateRange(periodo) : null,
    }
  }

  return null
}

export const convertRelatorioGerencialFormModelToStorageModel = (
  formModel: RelatorioGerencialFiltrosFormModel
): RelatorioGerencialFiltrosStorageModel => {
  const { periodo, ...rest } = formModel
  return {
    ...rest,
    periodo: periodo ? convertDateRangeToInstantRange(periodo) : null,
  }
}

export const convertCiapCidFilter = (
  ciapsCids: CiapCidSelectModel[],
  gruposCondicoesPrioritarios: GrupoCondicaoSelectModel[]
): string[] => {
  let filtroCiapCid: string[] = []
  if (gruposCondicoesPrioritarios && ciapsCids) {
    const ciapsCidsGrupoCondicoes = convertGrupoCondicoesPrioritariosToCiapsCids(gruposCondicoesPrioritarios)
    filtroCiapCid = [...convertCiapCidToRelatorioGerencialCiapCidFilter(ciapsCids.concat(ciapsCidsGrupoCondicoes))]
  }

  return filtroCiapCid
}

export const convertCiapCidToRelatorioGerencialCiapCidFilter = (ciapsCids: CiapCidSelectModel[]): Set<string> => {
  return new Set<string>(
    ciapsCids.map((ciapOuCid) =>
      isCiap(ciapOuCid)
        ? convertCiapToRelatorioGerencialCiapFilter(ciapOuCid)
        : convertCidToRelatorioGerencialCidFilter(ciapOuCid)
    )
  )
}

export const convertGrupoCondicoesPrioritariosToCiapsCids = (
  grupos: GrupoCondicaoSelectModel[]
): CiapCidSelectModel[] => {
  const ciapsCids: CiapCidSelectModel[] = []
  const ciaps = grupos.flatMap((grupo) => grupo.ciaps)
  const cids = grupos.flatMap((grupo) => grupo.cids)
  return ciapsCids.concat(ciaps, cids)
}

const convertCiapToRelatorioGerencialCiapFilter = (ciap: CiapCidModel): string => `Ciap-${ciap.codigo}`
const convertCidToRelatorioGerencialCidFilter = (cid: CiapCidModel): string => `Cid10-${cid.codigo}`

export function convertModeloPersonalizadoToFiltrosFormModel<T>({
  filters,
}: RelatorioGerencialModeloPersonalizado<T>): RelatorioGerencialFiltrosFormModel {
  return {
    opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum[filters.period],
    unidade: UnidadePeriodoRelatorioGerencialEnum[filters.unit],
    ciapsCids: filters.ciapsCids,
    gruposCondicoesPrioritarios: filters.grupos.map((grupo) => ({
      ...grupo,
      grupoCondicao: GrupoCondicaoEnum[grupo.grupoCondicao],
    })),
    periodo: {
      startDate: toDate(filters.startDate),
      endDate: toDate(filters.endDate),
    },
  }
}
