import { colors, Tag } from 'bold-ui'
import { SelectField } from 'components/form/final-form'
import { CiapCid10SelectFieldDocument } from 'graphql/hooks.generated'
import { CiapCid10SelectFieldQuery, CiapCid10SelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface CiapCidModel {
  id: ID
  codigo: string
}

interface CidSelectModel {
  nome?: string
}

interface CiapSelectModel {
  descricao?: string
}

export type CiapCidSelectModel = CiapCidModel & (CidSelectModel | CiapSelectModel)

export interface CiapCidSelectFieldProps extends Omit<AsyncSelectFieldProps<CiapCidSelectModel>, 'itemIsEqual'> {}

export function CiapCidSelectField(props: CiapCidSelectFieldProps) {
  const extractItems = (data: CiapCid10SelectFieldQuery) => {
    return data && orderItemsCombo(data.ciaps.content, data.cids.content)
  }

  const itemToString = (item: CiapCidSelectModel) =>
    item && `${isCiap(item) ? 'CIAP2' : 'CID10'} - ${getDescricao(item)} - ${item.codigo}`

  const orderItemsCombo = (
    ciaps: CiapCid10SelectFieldQuery['ciaps']['content'],
    cids: CiapCid10SelectFieldQuery['cids']['content']
  ) => {
    const items: CiapCidSelectModel[] = [...ciaps, ...cids]

    items.sort((a, b) => {
      return getDescricao(a).localeCompare(getDescricao(b))
    })

    return items
  }

  const renderItem = (item: CiapCidSelectModel) => {
    return (
      <>
        <Tag style={isCiap(item) ? { backgroundColor: colors.turquoise.c80 } : { backgroundColor: colors.pink.c80 }}>
          {isCiap(item) ? 'CIAP2' : 'CID10'}
        </Tag>
        <span style={{ marginLeft: '8px', fontWeight: 'bold' }}>
          {getDescricao(item)} - {item.codigo}
        </span>
      </>
    )
  }

  const variables = (inputQuery: string): CiapCid10SelectFieldQueryVariables => ({
    inputCiap: {
      query: inputQuery,
      pageParams: {
        ...DEFAULT_SELECT_PAGE_PARAM,
        size: 25,
      },
    },
    inputCid10: {
      query: inputQuery,
      pageParams: {
        ...DEFAULT_SELECT_PAGE_PARAM,
        size: 25,
      },
    },
  })

  const { selectProps } = useAsyncQuerySelect<
    CiapCidSelectModel,
    CiapCid10SelectFieldQuery,
    CiapCid10SelectFieldQueryVariables
  >({
    query: CiapCid10SelectFieldDocument,
    extractItems,
    variables,
  })

  return (
    <SelectField<CiapCidSelectModel> itemToString={itemToString} renderItem={renderItem} {...selectProps} {...props} />
  )
}

export const isCiap = (item): item is CiapSelectModel => !!item?.descricao
export const getDescricao = (item: CiapCidSelectModel): string => (isCiap(item) ? item.descricao : item.nome)
