import { Alert, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { useAcessoHasVinculacaoEquipeNasfQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'
import { emptyArray } from 'util/array'
import { MetaPath } from 'util/metaPath'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { AdicionarCuidadoCompartilhadoForm } from './cuidado-compartilhado-form/AdicionarCuidadoCompartilhadoForm'
import { EditarCuidadoCompartilhadoModalForm } from './cuidado-compartilhado-form/EditarCuidadoCompartilhadoModalForm'
import { CuidadoCompartilhadoTable } from './cuidado-compartilhado-table/CuidadoCompartilhadoTable'
import { CuidadoCompartilhadoPlanoModel, EDITAR_CUIDADO_COMPARTILHADO_URL } from './model-cuidadocompartilhado'

interface CuidadoCompartilhadoViewProps {
  name: MetaPath<CuidadoCompartilhadoPlanoModel[]>
}

export const CuidadoCompartilhadoView = (props: CuidadoCompartilhadoViewProps) => {
  const { name } = props

  const history = useHistory()
  const match = useRouteMatch()
  const { analytics } = useFirebase()
  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()
  const {
    data: { acessoHasVinculacaoEquipeNasf },
  } = useAcessoHasVinculacaoEquipeNasfQuery({ skip: PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED })

  const {
    prontuario: { id: prontuarioId },
    atendimentoProfissional: { lotacao, iniciadoEm },
  } = useAtendimentoContext()

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value = emptyArray },
  } = useEditableListField({ name })

  const handleEdit = (id: ID) => {
    history.push(`${match.url}${EDITAR_CUIDADO_COMPARTILHADO_URL}/${id}`)
  }

  const handleDelete = (id: ID) => {
    removeItem(value.find(({ _id: valueId }) => valueId === id))
    analytics.logEvent('CC_excluido')
  }

  return (
    <VFlow>
      {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED || acessoHasVinculacaoEquipeNasf ? (
        <AdicionarCuidadoCompartilhadoForm onSubmit={handleSubmit} />
      ) : (
        <Alert type='info'>
          Não é possível iniciar um compartilhamento de cuidado, pois não existem equipes NASF/eMulti vinculadas a
          equipe da sua lotação.
        </Alert>
      )}

      <CuidadoCompartilhadoTable
        prontuarioId={prontuarioId}
        cuidadosAtendimentoAtual={value}
        lotacaoAtual={lotacao}
        dataInicioAtendimento={iniciadoEm.getTime()}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Route path={`${match.url}${EDITAR_CUIDADO_COMPARTILHADO_URL}/:id`}>
        <EditarCuidadoCompartilhadoModalForm cuidadosCompartilhado={value} onSubmit={handleRowChanged} />
      </Route>
    </VFlow>
  )
}
