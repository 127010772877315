import { Heading, HFlow, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoCuidadoCompartilhadoDwDetalhesQuery } from 'graphql/hooks.generated'
import { HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery, Lotacao } from 'graphql/types.generated'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import {
  HistoricoDetalhesModel,
  IdadeOrigemAtendimento,
  InformacoesAdministrativas,
} from 'view/atendimentos/detail/historico/model/model-historico'
import { lotacaoToInformacoesAdministrativasProfissional } from 'view/atendimentos/detail/historico/utils/historicoUtils'
import { convertCiap, convertCid10, isCodigoNaoInformado } from 'view/atendimentos/detail/utils-cuidadoCompartilhado'
import {
  ClassificacaoPrioridadeCuidadoRecord,
  LabelCondutaCuidadoCompartilhadoDwRecord,
  LabelPrioridadeCuidadoCompartilhadoDwRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import StatusSquare from '../../../StatusSquare'
import CiapCidLabel from '../../header/components/CiapCidLabel'
import HistoricoGapPanel from '../HistoricoGapPanel'
import { HistoricoCuidadoCompartilhadoConduta } from './HistoricoCuidadoCompartilhadoConduta'

interface HistoricoCuidadoCompartilhadoPecPanelProps {
  historicoDetalhes: HistoricoDetalhesModel
  idadeOrigemAtendimento: IdadeOrigemAtendimento
}

export type CuidadoCompartilhadoEvolucaoDetalhes = HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery['cuidadoCompartilhadoEvolucao']

export const HistoricoCuidadoCompartilhadoDwPanel = (props: HistoricoCuidadoCompartilhadoPecPanelProps) => {
  const { historicoDetalhes, idadeOrigemAtendimento } = props

  const {
    data: { historicoCuidadoCompartilhadoDw },
    loading,
  } = useHistoricoCuidadoCompartilhadoDwDetalhesQuery({
    variables: {
      input: {
        uuidRegistro: historicoDetalhes.codigoUnicoRegistro,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />
  if (isUndefinedOrNull(historicoDetalhes.codigoUnicoRegistro)) return null

  const informacoesAdministrativas = getInformacoesAdministrativas(
    historicoDetalhes,
    idadeOrigemAtendimento,
    historicoCuidadoCompartilhadoDw?.isExecutante
  )

  const classificacaoPrioridade = LabelPrioridadeCuidadoCompartilhadoDwRecord[historicoDetalhes.classificacaoPrioridade]

  const hasReclassificacaoPrioridade = !isCodigoNaoInformado(historicoDetalhes.reclassificacaoPrioridade)
  const reclassificacaoPrioridade =
    LabelPrioridadeCuidadoCompartilhadoDwRecord[historicoDetalhes.reclassificacaoPrioridade]
  const reclassificacao = ClassificacaoPrioridadeCuidadoRecord[reclassificacaoPrioridade]

  const ciap = convertCiap(historicoDetalhes)
  const cid10 = convertCid10(historicoDetalhes)

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={informacoesAdministrativas}
      dataInicioCompartilhamento={undefined}
    >
      <VFlow vSpacing={0.5}>
        <VFlow vSpacing={0}>
          <Heading level={5}>Problemas e condições</Heading>
          <CiapCidLabel ciap={ciap} cid10={cid10} isCapitalized />
        </VFlow>
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={4}>Conduta</Heading>

        {hasReclassificacaoPrioridade && (
          <HFlow hSpacing={0.5}>
            <Heading level={5}>Reclassificação de prioridade para</Heading>
            <StatusSquare color={reclassificacao.cor} description={reclassificacao.descricao} />
          </HFlow>
        )}

        <VFlow vSpacing={0}>
          <HistoricoCuidadoCompartilhadoConduta
            cuidadoCompartilhadoEvolucao={{
              classificacaoPrioridade: classificacaoPrioridade,
              reclassificacaoPrioridade: reclassificacaoPrioridade,
              conduta: LabelCondutaCuidadoCompartilhadoDwRecord[historicoDetalhes.conduta],
              cuidadoCompartilhado: null,
              lotacao: null,
              dataEvolucao: idadeOrigemAtendimento.dataAtendimento,
              id: null,
            }}
          />
        </VFlow>
      </VFlow>
    </HistoricoGapPanel>
  )
}

const getInformacoesAdministrativas = (
  detalhes: HistoricoDetalhesModel,
  idadeOrigemAtendimento: IdadeOrigemAtendimento,
  isExecutante: boolean
): InformacoesAdministrativas => {
  const executante = convertToLotacao(
    detalhes.nomeExecutante,
    detalhes.cboExecutante,
    detalhes.nomeUbsExecutante,
    detalhes.cnsExecutante,
    detalhes.siglaEquipeExecutante,
    detalhes.ineExecutante
  )
  const solicitante = convertToLotacao(
    detalhes.nomeSolicitante,
    detalhes.cboSolicitante,
    detalhes.nomeUbsSolicitante,
    detalhes.cnsSolicitante,
    detalhes.siglaEquipeSolicitante,
    detalhes.ineSolicitante
  )

  return {
    localAtendimento: idadeOrigemAtendimento.origemAtendimento,
    stCuidadoCompartilhado: true,
    profissionalPrincipal: lotacaoToInformacoesAdministrativasProfissional(isExecutante ? executante : solicitante),
    profissionalCompartilhado: lotacaoToInformacoesAdministrativasProfissional(isExecutante ? solicitante : executante),
  }
}

const convertToLotacao = (nome: string, cbo: string, ubs: string, cnes: string, equipe: string, ine: string): Lotacao =>
  ({
    profissional: { nome: nome },
    cbo: { nome: cbo },
    unidadeSaude: { nome: ubs, cnes: cnes },
    equipe: { nome: equipe, ine: ine },
  } as Lotacao)
