import { css } from '@emotion/core'
import { Alert, Cell, Grid, HFlow, InfoLabel, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { useErrorHandler } from 'components/error'
import { FooterButton } from 'components/footer-button'
import { CheckboxField, DateField, Form, FormRenderProps, TextAreaField, TextField } from 'components/form'
import { DoseImunobiologicoVacinacaoSelectField } from 'components/form/field/select/DoseImunobiologicoVacinacaoSelectField'
import { EstrategiaVacinacaoSelectField } from 'components/form/field/select/EstrategiaVacinacaoSelectField'
import { GrupoAtendimentoSelectField } from 'components/form/field/select/GrupoAtendimentoSelectField'
import { LocalAplicacaoSelectField } from 'components/form/field/select/LocalAplicacaoSelectField'
import { LoteImunobiologicoSelectField } from 'components/form/field/select/LoteImunobiologicoSelectField/LoteImunobiologicoSelectField'
import { ViaAdministracaoVacinaSelectField } from 'components/form/field/select/ViaAdministracaoVacinaSelectField'
import { addDays, format } from 'date-fns'
import { FormApi } from 'final-form'
import { useProximaDoseAprazamentoQuery } from 'graphql/hooks.generated'
import {
  OrigemAtendimento,
  ProximaDoseAprazamentoQueryInput,
  SexoEnum,
  TipoRegistroVacinacaoEnum,
} from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { Fragment, useMemo } from 'react'
import { FormSpy } from 'react-final-form'
import { formatDayMonthYear } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'
import { createValidator, maxLength, nomeFabricante, nomeLote, required } from 'util/validation'
import {
  ImunobiologicoSelectField,
  ImunobiologicoSelectModel,
} from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'
import { LoteImunobiologicoFabricanteSelectField } from 'view/lote-imunobiologico/components/LoteImunobiologicoFabricanteSelectField'
import { covidImunosRecord } from 'view/lote-imunobiologico/record'

import { RegistroVacinacaoFormInput } from '../detail/vacinacao/VacinacaoCalendarioView'
import { createAplicacaoDoseModalCalculator, createOutrosImunobiologicosCalculator } from './calculator'
import { ImunobiologicoModel, isBcg, isDtpaAdulto, RegistroVacinacaoFormModel } from './model'
import { sairSemSalvarConfirm } from './VacinacaoModal'
import { VacinacaoModalHeader } from './VacinacaoModalHeader'
import { estrategiaWithGrupoAtendimento, isBeforeDataAprazamento } from './vacinacaoUtils'
import { validateContraIndicacaoAplicacaoOutrosImunos } from './validator'

interface AplicacaoVacinaModalProps {
  initialValues?: RegistroVacinacaoFormModel
  imunobiologico?: ImunobiologicoModel
  doseId?: ID
  doseNome?: string
  idadeRecomendada?: string
  modalOpen: boolean
  handleModalClose(): void
  handleOnSubmit(input: RegistroVacinacaoFormInput): Promise<void>
  outrosImunobiologicos?: boolean
  sexo?: SexoEnum
  gestante: boolean
  dataAtendimento?: Instant
}

const meta = metaPath<RegistroVacinacaoFormModel>()

const validator = (outrosImunobiologicos: boolean, dataMinimaAprazProx: Date) =>
  createValidator<RegistroVacinacaoFormModel>(
    {
      estrategiaVacinacao: !outrosImunobiologicos && [required],
      viaAdministracao: [required],
      loteFabricante: [maxLength(60)],
      loteNome: [maxLength(30)],
      outrosImunosImunobiologico: outrosImunobiologicos && [required],
    },
    (values: RegistroVacinacaoFormModel, errors) => {
      if (values.isCadastrarNovoLote) {
        errors.loteNome = required(values.loteNome)
        if (!errors.loteNome) errors.loteNome = nomeLote(values.loteNome)

        errors.loteFabricante = required(values.loteFabricante?.nome)
        if (!errors.loteFabricante) errors.loteFabricante = nomeFabricante(values.loteFabricante?.nome)

        errors.loteDataValidade = required(values.loteDataValidade)
      } else if (!outrosImunobiologicos) {
        errors.loteImunobiologico = required(values.loteImunobiologico)
      }

      if (values.viaAdministracao) {
        errors.localAplicacaoVacinacao = required(values.localAplicacaoVacinacao)
      }

      if (isBeforeDataAprazamento(values.dataAprazamento, dataMinimaAprazProx)) {
        errors.dataAprazamento = 'Deve ser posterior à data atual.'
      }

      if (isBeforeDataAprazamento(values.dataAprazamentoProxDose, dataMinimaAprazProx)) {
        errors.dataAprazamentoProxDose = 'Deve ser posterior à data atual.'
      }

      if (estrategiaWithGrupoAtendimento(values.estrategiaVacinacao?.estrategiaVacinacaoDbEnum)) {
        errors.grupoAtendimento = required(values.grupoAtendimento)
      }

      if (outrosImunobiologicos) {
        if (values.outrosImunosImunobiologico) {
          errors.estrategiaVacinacao = required(values.estrategiaVacinacao)
          if (!values.isCadastrarNovoLote) errors.loteImunobiologico = required(values.loteImunobiologico)
        }

        if (values.outrosImunosImunobiologico && values.estrategiaVacinacao) {
          errors.outrosImunosDose = required(values.outrosImunosDose)
        }
      }

      return errors
    }
  )

const dataMinimaAprazProx = (dataServidor: Date) => addDays(dataServidor, 1)

export const AplicacaoVacinaModal = (props: AplicacaoVacinaModalProps) => {
  const {
    imunobiologico,
    doseId,
    doseNome,
    modalOpen,
    handleModalClose,
    handleOnSubmit,
    idadeRecomendada,
    initialValues,
    outrosImunobiologicos,
    sexo,
    gestante,
    dataAtendimento,
  } = props

  const { getServerTimeNow } = useServerTime()
  const serverTimeNow = getServerTimeNow()
  const alert = useAlert()

  const {
    data: { proximaDoseAprazamentoAutomatico },
    refetch,
  } = useProximaDoseAprazamentoQuery({ skip: true, fetchPolicy: 'no-cache' })

  const handleRejection = useErrorHandler()

  const handleSubmit = async (formValues: RegistroVacinacaoFormModel, formApi: FormApi) => {
    return handleOnSubmit({
      formValues: {
        ...formValues,
        tipoRegistroVacinacao: TipoRegistroVacinacaoEnum.APLICACAO,
        imunobiologicoId: imunobiologico?.id,
        imunobiologicoNome: imunobiologico?.nome,
        imunobiologicoSigla: imunobiologico?.sigla,
        doseId: doseId,
        dataAplicacao: new Date(dataAtendimento).toISOString(),
        dataRegistro: new Date(dataAtendimento).toISOString(),
        origemDados: OrigemAtendimento.PEC,
        doseNome,
      },
      onSuccess: () => {
        setTimeout(formApi.reset)
        handleModalClose()
        alert('success', 'Registro salvo com sucesso.')
      },
    })
  }

  const onModalClose = (formRenderProps: FormRenderProps<RegistroVacinacaoFormModel>) => {
    if (formRenderProps.dirty) {
      sairSemSalvarConfirm(() => {
        setTimeout(formRenderProps.form.reset)
        handleModalClose()
      })
    } else {
      handleModalClose()
    }
  }

  const getDataProximoAprazamento = (
    formChange: (name: string, value?: any) => void,
    batch: (fn: () => void) => void,
    input: ProximaDoseAprazamentoQueryInput
  ) => {
    refetch({
      input: {
        doseId: input.doseId,
        estrategiaId: input.estrategiaId,
        imunobiologicoId: input.imunobiologicoId,
      },
    })
      .then(({ data: { proximaDoseAprazamentoAutomatico } }) => {
        if (!!proximaDoseAprazamentoAutomatico) {
          batch(() => {
            proximaDoseAprazamentoAutomatico.diasAprazamento &&
              formChange(
                meta.dataAprazamentoProxDose.absolutePath(),
                format(addDays(dataAtendimento, proximaDoseAprazamentoAutomatico.diasAprazamento), 'yyyy-MM-dd')
              )

            formChange(meta.proximaDoseSigla.absolutePath(), proximaDoseAprazamentoAutomatico.proximaDoseSigla)
            formChange(meta.proximaDoseId.absolutePath(), proximaDoseAprazamentoAutomatico.proximaDoseId)
            formChange(meta.proximaDoseNome.absolutePath(), proximaDoseAprazamentoAutomatico.proximaDoseNome)
          })
        } else {
          batch(() => {
            formChange(meta.dataAprazamentoProxDose.absolutePath(), undefined)
            formChange(meta.proximaDoseSigla.absolutePath(), undefined)
            formChange(meta.proximaDoseId.absolutePath(), undefined)
            formChange(meta.proximaDoseNome.absolutePath(), undefined)
          })
        }
      })
      .catch(handleRejection)
  }

  const renderForm = (formRenderProps: FormRenderProps<RegistroVacinacaoFormModel>) => {
    return (
      <Modal open={modalOpen} onClose={() => onModalClose(formRenderProps)} size='auto' closeOnBackdropClick={false}>
        <ModalBody>
          <VFlow>
            <VacinacaoModalHeader
              idadeRecomendada={idadeRecomendada}
              imunobiologicoNome={imunobiologico?.nome}
              imunobiologicoSigla={imunobiologico?.sigla}
              nomeDose={doseNome}
              outrosImunobiologicos={outrosImunobiologicos}
              labelTipoRegistro='Aplicação'
              isSubModalHeader
            />
            <Grid
              style={css`
                width: 54.7rem;
              `}
            >
              {isDtpaAdulto(imunobiologico?.id) && (
                <Cell size={12}>
                  <Alert type='info' inline>
                    É recomendado aplicar a {doseNome} do imunobiológico {imunobiologico?.nome} somente a partir da 20ª
                    semana de gestação.
                  </Alert>
                </Cell>
              )}
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <Fragment>
                    {outrosImunobiologicos && (
                      <Fragment>
                        <Cell size={6}>
                          <ImunobiologicoSelectField
                            name={meta.outrosImunosImunobiologico}
                            label='Imunobiológico'
                            required
                            outrosImunobiologicos
                            sexo={sexo}
                            onChange={(actualValue: ImunobiologicoSelectModel) => {
                              gestante &&
                                validateContraIndicacaoAplicacaoOutrosImunos(
                                  actualValue?.id,
                                  formRenderProps.form.change,
                                  meta
                                )
                            }}
                          />
                        </Cell>
                        <Cell size={6} />
                      </Fragment>
                    )}
                    <Cell size={6}>
                      <EstrategiaVacinacaoSelectField
                        name={meta.estrategiaVacinacao}
                        label='Estratégia'
                        imunobiologicoId={imunobiologico?.id || values.outrosImunosImunobiologico?.id}
                        doseImunobiologicoId={doseId || values.outrosImunosDose?.id}
                        disabled={outrosImunobiologicos && !values.outrosImunosImunobiologico}
                        required={outrosImunobiologicos ? values.outrosImunosImunobiologico : true}
                        outrosImunobiologicos={outrosImunobiologicos}
                        sexo={sexo}
                        onChange={(actualValue) =>
                          !outrosImunobiologicos &&
                          actualValue &&
                          getDataProximoAprazamento(formRenderProps.form.change, formRenderProps.form.batch, {
                            imunobiologicoId: imunobiologico?.id,
                            estrategiaId: actualValue.id,
                            doseId: doseId,
                          })
                        }
                      />
                    </Cell>
                    <Cell size={6}>
                      {estrategiaWithGrupoAtendimento(values.estrategiaVacinacao?.estrategiaVacinacaoDbEnum) && (
                        <GrupoAtendimentoSelectField
                          name={meta.grupoAtendimento}
                          label='Grupo de atendimento'
                          required
                          onlyAtivos
                        />
                      )}
                    </Cell>
                    {outrosImunobiologicos && (
                      <Cell size={6}>
                        <DoseImunobiologicoVacinacaoSelectField
                          name={meta.outrosImunosDose}
                          label='Dose'
                          imunobiologicoIds={[values.outrosImunosImunobiologico?.id]}
                          estrategiaId={values.estrategiaVacinacao?.id}
                          sexo={sexo}
                          required={values.estrategiaVacinacao}
                          disabled={!values.estrategiaVacinacao && !proximaDoseAprazamentoAutomatico}
                          onChange={(actualValue) =>
                            actualValue &&
                            getDataProximoAprazamento(formRenderProps.form.change, formRenderProps.form.batch, {
                              imunobiologicoId: values.outrosImunosImunobiologico?.id,
                              estrategiaId: values.estrategiaVacinacao?.id,
                              doseId: actualValue?.id,
                            })
                          }
                        />
                      </Cell>
                    )}
                    <Cell size={3}>
                      <DateField
                        label='Aprazamento da próxima dose'
                        name={meta.dataAprazamentoProxDose}
                        icon='calendarOutline'
                        minDate={dataMinimaAprazProx(serverTimeNow)}
                        disabled={
                          !outrosImunobiologicos
                            ? !values.estrategiaVacinacao || !values.proximaDoseId
                            : !values.outrosImunosDose || !values.proximaDoseId
                        }
                      />
                    </Cell>
                    {values.dataAprazamentoProxDose && (
                      <Cell size={3}>
                        <InfoLabel
                          title={`${values?.proximaDoseSigla} será aprazada para`}
                          childStyles={css`
                            margin-top: 0.6rem;
                          `}
                        >
                          <Text>{formatDayMonthYear(values.dataAprazamentoProxDose)}</Text>
                        </InfoLabel>
                      </Cell>
                    )}
                    {!outrosImunobiologicos && <Cell size={4} />}
                    <Cell size={6}>
                      <LoteImunobiologicoSelectField
                        name={meta.loteImunobiologico}
                        label='Lote/Fabricante'
                        imunobiologicoId={imunobiologico?.id || values.outrosImunosImunobiologico?.id}
                        required={
                          (!outrosImunobiologicos && !values.isCadastrarNovoLote) ||
                          (outrosImunobiologicos && values.outrosImunosImunobiologico && !values.isCadastrarNovoLote)
                        }
                        disabled={
                          values.isCadastrarNovoLote || (outrosImunobiologicos && !values.outrosImunosImunobiologico)
                        }
                      />
                    </Cell>
                    <Cell
                      size={6}
                      style={css`
                        margin-top: 2rem;
                      `}
                    >
                      <CheckboxField label='Cadastrar novo lote' name={meta.isCadastrarNovoLote} />
                    </Cell>
                    {values.isCadastrarNovoLote ? (
                      <Cell size={12}>
                        <Box>
                          <Grid>
                            <Cell size={6}>
                              <TextField name={meta.loteNome} label='Lote' maxLength={30} required />
                            </Cell>
                            <Cell size={6}>
                              <LoteImunobiologicoFabricanteSelectField
                                name={meta.loteFabricante}
                                label='Fabricante'
                                required={
                                  !covidImunosRecord[imunobiologico?.id || values.outrosImunosImunobiologico?.id]
                                }
                                icon={null}
                                disabled={
                                  !!covidImunosRecord[imunobiologico?.id || values.outrosImunosImunobiologico?.id]
                                }
                                maxLength={60}
                              />
                            </Cell>
                            <Cell size={4}>
                              <DateField
                                label='Data de validade'
                                name={meta.loteDataValidade}
                                icon='calendarOutline'
                                required
                              />
                            </Cell>
                          </Grid>
                        </Box>
                      </Cell>
                    ) : null}
                    <Cell size={6}>
                      <ViaAdministracaoVacinaSelectField
                        name={meta.viaAdministracao}
                        label='Via de Administração'
                        required
                      />
                    </Cell>
                    <Cell size={6}>
                      <LocalAplicacaoSelectField
                        name={meta.localAplicacaoVacinacao}
                        label='Local de aplicação'
                        viaAdministracaoId={values.viaAdministracao?.id}
                        required={values.viaAdministracao}
                        disabled={!values.viaAdministracao}
                      />
                    </Cell>
                    <Cell size={12}>
                      <TextAreaField
                        style={css`
                          height: 5rem;
                          resize: none;
                        `}
                        name={meta.observacoes}
                        label='Observações'
                        maxLength={300}
                      />
                    </Cell>
                    {isBcg(imunobiologico?.id || values.outrosImunosImunobiologico?.id) && (
                      <Cell
                        size={12}
                        style={css`
                          margin-top: -1rem;
                        `}
                      >
                        <CheckboxField label='Comunicante de hanseníase' name={meta.isComunicanteHanseniase} />
                      </Cell>
                    )}
                  </Fragment>
                )}
              </FormSpy>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <FooterButton kind='normal' onClick={() => onModalClose(formRenderProps)}>
              Cancelar
            </FooterButton>
            <FooterButton kind='primary' onClick={formRenderProps.handleSubmit}>
              Salvar
            </FooterButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const validatorAplicacao = useMemo(() => validator(outrosImunobiologicos, dataMinimaAprazProx(serverTimeNow)), [
    serverTimeNow,
    outrosImunobiologicos,
  ])
  const decorators = useMemo(
    () =>
      outrosImunobiologicos
        ? [createAplicacaoDoseModalCalculator(meta), createOutrosImunobiologicosCalculator(meta)]
        : [createAplicacaoDoseModalCalculator(meta)],
    [outrosImunobiologicos]
  )

  return (
    <Form<RegistroVacinacaoFormModel>
      render={renderForm}
      subscription={{ submitting: true, dirty: true }}
      decorators={decorators}
      validate={validatorAplicacao}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      suppressNotificationError
    />
  )
}
