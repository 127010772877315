/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Heading, isEmpty, Text, VFlow } from 'bold-ui'
import { isProcedimentoAdministrativo } from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoEscutaInicialQuery } from 'graphql/hooks.generated'
import { ClassificacaoRisco, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { partition } from 'lodash'
import { memo } from 'react'
import {
  HistoricoPecPanelProps,
  recordEscutaHistoricoTagStyle,
} from 'view/atendimentos/detail/historico/model/model-historico'
import {
  ClassificacaoRiscoColor,
  hasMedicoes,
  mountInfosAdministrativasAtendimentosRecentes,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/historicoUtils'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import StatusSquare from '../../../StatusSquare'
import MotivoConsultaBox from '../atendimento-individual/consulta/MotivoConsultaBox'
import HistoricoGapPanel from '../HistoricoGapPanel'
import HistoricoMedicoesTableBox from '../HistoricoMedicoesTableBox'
import HistoricoEscutaInicialProcedimentoTable from './HistoricoEscutaInicialProcedimentoTable'

interface HistoricoEscutaInicialPecProps extends HistoricoPecPanelProps {}

function HistoricoEscutaInicialPec(props: HistoricoEscutaInicialPecProps) {
  const { idAtend, idadeOrigemAtendimento } = props
  const {
    data: { historicoAtendimentoPec: historico },
    loading,
  } = useHistoricoEscutaInicialQuery({
    variables: {
      input: {
        idAtend,
        isOrigemPec: true,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return null

  const isAgudo = historico.classificacaoRisco !== ClassificacaoRisco.PEC_NAO_AGUDA
  const procedimentos = historico.procedimentosAtendimento?.map((i) => i.procedimento)
  const medicoes = historico.medicoes?.map((medicao) => medicao as HistoricoMedicaoModel)
  const isUpa = historico.lotacao.unidadeSaude.tipoEstabelecimento === TipoEstabelecimentoEnum.UPA
  const [procedimentosAdm, procedimentosClinicos] = partition(procedimentos, (procedimento) =>
    isProcedimentoAdministrativo(procedimento.tipoProcedimento)
  )

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentosRecentes(historico)}
    >
      <VFlow>
        <MotivoConsultaBox subjetivoCiaps={historico.evolucaoSubjetivo?.ciaps} />
        <Text dangerouslySetInnerHTML={{ __html: historico.evolucaoSubjetivo?.descricao }} />
        {hasMedicoes(medicoes) && <HistoricoMedicoesTableBox sectionStyle='I' medicoes={medicoes} />}
        {!isEmpty(historico.procedimentosAtendimento) && (
          <VFlow vSpacing={0.5}>
            <Heading level={5}>Intervenções e/ou procedimentos clínicos</Heading>
            <HistoricoEscutaInicialProcedimentoTable procedimentos={procedimentosClinicos} />
          </VFlow>
        )}
        {historico.classificacaoRisco && historico.classificacaoRisco !== ClassificacaoRisco.NAO_CLASSIFICADO && (
          <VFlow vSpacing={0.5}>
            <Heading level={3}>Classificação de risco/Vulnerabilidade</Heading>
            <StatusSquare
              fontWeight='bold'
              color={ClassificacaoRiscoColor[historico.classificacaoRisco]}
              description={`${isAgudo ? 'Aguda - ' : ''}${
                recordEscutaHistoricoTagStyle[historico.classificacaoRisco].descricao
              }`}
            />
          </VFlow>
        )}
        {isUpa && (
          <VFlow vSpacing={0.5}>
            <Heading level={3}>Dados de desfecho</Heading>
            {!isEmpty(procedimentosAdm) ? (
              <VFlow vSpacing={0.5}>
                <Heading level={5}>Procedimentos Administrativos (SIGTAP)</Heading>
                <HistoricoEscutaInicialProcedimentoTable procedimentos={procedimentosAdm} />
              </VFlow>
            ) : (
              <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
            )}
          </VFlow>
        )}
      </VFlow>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoEscutaInicialPec)
