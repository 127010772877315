import { InstalacaoInput } from 'graphql/types.generated'
import { urlToModel } from 'util/urlToModel'
import { ErrorObject } from 'util/validation'
import convertProfissional from 'view/profissional/convertModelToInput'

import { InstalacaoFormModel } from './InstalacaoRootView'

export function convertToInput(values: InstalacaoFormModel): InstalacaoInput {
  const { nomeInstalacao, tipoInstalacao } = (values || {}).step1
  const { novaSenhaInput, ...profissional } = (values || {}).step2

  const linkInstalacao = urlToModel(values?.step1.linkInstalacao)

  return {
    dadosInstalacao: {
      linkInstalacao,
      nomeInstalacao,
    },
    tipoInstalacao,
    novaSenha: novaSenhaInput.novaSenha,
    profissional: profissional && convertProfissional(profissional),
  }
}

export function convertErrors(errors: ErrorObject<InstalacaoInput>): ErrorObject<InstalacaoFormModel> {
  const { profissional, ...rest } = errors
  return {
    step1: rest.dadosInstalacao &&
      typeof rest.dadosInstalacao !== 'string' && { ...rest.dadosInstalacao, tipoInstalacao: rest.tipoInstalacao },
    step2: { ...(profissional as ErrorObject<InstalacaoFormModel['step2']>), novaSenhaInput: rest.novaSenha },
  }
}
