import { Cbo, Lotacao, UnidadeSaude } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { MetaRoot } from 'util/metaPath'

import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'

export const cuidadoCompartilhadoFormCalculator = (meta: MetaRoot<CuidadoCompartilhadoPlanoModel>, flag: boolean) => {
  return flag
    ? [
        {
          field: meta.cbo.absolutePath(),
          updates: {
            [meta.unidadeSaude.absolutePath()]: (value: Cbo, _, prevValues: CuidadoCompartilhadoPlanoModel) => {
              return isUndefinedOrNull(value) ? null : prevValues.unidadeSaude
            },
            [meta.lotacao.absolutePath()]: (value: Cbo, _, prevValues: CuidadoCompartilhadoPlanoModel) => {
              return isUndefinedOrNull(value) ? null : prevValues.lotacao
            },
          },
        },
        {
          field: meta.unidadeSaude.absolutePath(),
          updates: {
            [meta.lotacao.absolutePath()]: (
              value: UnidadeSaude,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              return isUndefinedOrNull(value) ||
                (!isEmpty(prevValues) && value?.id !== prevValues.lotacao?.unidadeSaude?.id)
                ? null
                : allValues.lotacao
            },
          },
        },
      ]
    : [
        {
          field: meta.lotacao.absolutePath(),
          updates: {
            [meta.cbo.absolutePath()]: (value: Lotacao, _, prevValues: CuidadoCompartilhadoPlanoModel) => {
              if (!isUndefinedOrNull(value)) return value.cbo
              return prevValues.cbo
            },
          },
        },
        {
          field: meta.cbo.absolutePath(),
          updates: {
            [meta.lotacao.absolutePath()]: (
              value: Cbo,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              return isUndefinedOrNull(value) || (!isEmpty(prevValues) && value?.id !== prevValues.lotacao?.cbo?.id)
                ? null
                : allValues.lotacao
            },
          },
        },
      ]
}
