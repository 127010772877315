import { Alert, DateRange, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable, PivotTableProps } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/tree-builder/useTreeBuilder'
import { useFlags } from 'config/useFlagsContext'
import { useAbsenteismosLazyQuery, useModeloPersonalizadoRelatorioGerencialQuery } from 'graphql/hooks.generated'
import { TipoModuloEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import {
  NO_ASYNC_LOADING_MESSAGE,
  RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO,
} from '../common/model-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../components/form/RelatorioGerencialFiltrosForm'
import {
  modeloPersonalizadoToPivotTableProps,
  modeloPersonalizadoToTableWrapperProps,
} from '../components/modelo-personalizado/converter-modeloPersonalizadoRelatorioGerencial'
import { ModeloPersonalizadoModel } from '../components/modelo-personalizado/model-modeloPersonalizado'
import { RelatorioGerencialTableWrapper } from '../components/RelatorioGerencialTableWrapper'
import { absenteismoKeyMapping } from './keyMapping-relatorioGerencialAbsenteismo'
import { RelatorioGerencialAbsenteismoModel } from './model-relatorioGerencialAbsenteismo'

const NAME = 'Relatório gerencial de absenteísmo'

export function RelatorioGerencialAbsenteismoView() {
  const [getAbsenteismos, { loading, data }] = useAbsenteismosLazyQuery({
    fetchPolicy: 'cache-first',
  })
  const [modeloPersonalizadoSelecionado, setModeloPersonalizadoSelecionado] = useState<ModeloPersonalizadoModel>(null)

  const { analytics } = useFirebase()

  const { MODELOS_PERSONALIZADOS_ENABLED } = useFlags()

  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()

  const handleSubmit = useCallback(
    ({ periodo }: RelatorioGerencialFiltrosFormModel) => {
      setDateRangeFilter(periodo)
      getAbsenteismos({
        variables: {
          input: { startDate: periodo.startDate, endDate: periodo.endDate },
        },
      })
    },
    [getAbsenteismos]
  )

  const {
    data: { modeloPersonalizadoRelatorioGerencialById: dataModeloPersonalizado },
    loading: loadingModeloPersonalizado,
  } = useModeloPersonalizadoRelatorioGerencialQuery({
    skip: MODELOS_PERSONALIZADOS_ENABLED || isUndefinedOrNull(modeloPersonalizadoSelecionado?.id),
    variables: { id: modeloPersonalizadoSelecionado?.id },
  })

  const isDataNotUndefinedOrNull = !isUndefinedOrNull(data?.absenteismos)
  const isEmpty = isDataNotUndefinedOrNull && data?.absenteismos.isEmpty()

  useEffect(() => {
    analytics.logEvent(RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO, {
      relatorio: NAME,
    })
  }, [analytics])

  const initialValuesFromModelo = useMemo(() => modeloPersonalizadoToTableWrapperProps(dataModeloPersonalizado), [
    dataModeloPersonalizado,
  ])

  return (
    <>
      <Breadcrumb title={NAME} />
      <PageHeaderSection title={NAME} />
      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            O relatório de absenteísmo apresenta a contagem dos agendamentos em que o cidadão não compareceu ou não
            aguardou.
          </Alert>

          <RelatorioGerencialTableWrapper
            loading={loading || loadingModeloPersonalizado}
            isEmpty={isEmpty}
            onSubmit={handleSubmit}
            modulo={TipoModuloEnum.RELATORIO_GERENCIAL_ABSENTEISMO}
            keyMapping={absenteismoKeyMapping}
            loadingMessage={NO_ASYNC_LOADING_MESSAGE}
            filtrosLocalStorage={initialValuesFromModelo}
            setModeloSelecionado={setModeloPersonalizadoSelecionado}
          >
            {isDataNotUndefinedOrNull && (
              <AbsenteismoTable
                data={data.absenteismos}
                dateRangeFilter={dateRangeFilter}
                initialValues={modeloPersonalizadoToPivotTableProps(absenteismoKeyMapping, dataModeloPersonalizado)}
              />
            )}
          </RelatorioGerencialTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}

interface AbsenteismoTableProps extends Pick<PivotTableProps<RelatorioGerencialAbsenteismoModel>, 'initialValues'> {
  data: RelatorioGerencialAbsenteismoModel[]
  dateRangeFilter: DateRange
}

function AbsenteismoTable(props: AbsenteismoTableProps) {
  const { data, dateRangeFilter } = props

  const {
    metaState,
    treeBuilderStatus: { isBuilding },
    ...rest
  } = useTreeBuilder<RelatorioGerencialAbsenteismoModel>({
    keyMapping: absenteismoKeyMapping,
    data: data,
  })

  if (metaState?.keyValues && metaState?.numberKeys) {
    return (
      <PivotTable<RelatorioGerencialAbsenteismoModel>
        treeMeta={metaState}
        keyMapping={absenteismoKeyMapping}
        title={NAME}
        dateRangeFilter={dateRangeFilter}
        isBuilding={isBuilding}
        {...rest}
      />
    )
  } else {
    return null
  }
}
