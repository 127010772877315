import { TODOS_OS_ITEMS } from 'components/form/field/select/CampoRelatorioGerencialSelectField/CampoRelatorioGerencialSelectField'
import { Calculation } from 'final-form-calculate'
import { MetaRoot } from 'util/metaPath'

import { absenteismoKeyMapping } from '../absenteismo/keyMapping-relatorioGerencialAbsenteismo'
import { RelatorioGerencialAbsenteismoModel } from '../absenteismo/model-relatorioGerencialAbsenteismo'
import { atendimentoProfissionalKeyMapping } from '../atendimentos/keyMapping-relatorioGerencialAtendimento'
import { RelatorioGerencialAtendimentoProfissionalModel } from '../atendimentos/model-relatorioGerencialAtendimentoProfissional'
import { RelatorioGerencialFiltrosFormModel } from '../components/form/RelatorioGerencialFiltrosForm'
import { cuidadoCompartilhadoKeyMapping } from '../cuidado-compartilhado/keyMapping-relatorioGerencialCuidadoCompartilhado'
import { RelatorioGerencialCuidadoCompartilhadoModel } from '../cuidado-compartilhado/model-relatorioGerencialCuidadoCompartilhado'

const IGNORED_FIELD_KEYS: Array<
  | keyof RelatorioGerencialAtendimentoProfissionalModel
  | keyof RelatorioGerencialAbsenteismoModel
  | keyof RelatorioGerencialCuidadoCompartilhadoModel
> = ['uf', 'dia', 'semana']

const ALL_FIELD_KEYS = [
  ...atendimentoProfissionalKeyMapping.keys(),
  ...absenteismoKeyMapping.keys(),
  ...cuidadoCompartilhadoKeyMapping.keys(),
].filter((key) => !IGNORED_FIELD_KEYS.includes(key))

export const createCamposDisponiveisSelectFieldCalculationByKey = (
  meta: MetaRoot<RelatorioGerencialFiltrosFormModel>,
  key: string
): Calculation => ({
  field: meta.camposDisponiveis[key].absolutePath(),
  updates: {
    [meta.camposDisponiveis[key].absolutePath()]: (
      items: string[],
      _: RelatorioGerencialFiltrosFormModel,
      prevValues: RelatorioGerencialFiltrosFormModel
    ): string[] => {
      if (items?.length > 1 && items?.includes(TODOS_OS_ITEMS)) {
        if (prevValues?.camposDisponiveis?.[key]?.includes(TODOS_OS_ITEMS)) {
          return items.filter((item) => item !== TODOS_OS_ITEMS)
        } else {
          return [TODOS_OS_ITEMS]
        }
      }

      return items
    },
  },
})

// TODO (eldorado #19208) manter somente um calculation
export const createCamposDisponiveisSelectFieldCalculations = (
  meta: MetaRoot<RelatorioGerencialFiltrosFormModel>
): Calculation[] => ALL_FIELD_KEYS.map((key) => createCamposDisponiveisSelectFieldCalculationByKey(meta, key))
