import { handleError } from 'components/error'
import { FORM_ERROR } from 'final-form'

export const buildAgendamentosConflitantesErrorMessage = (apolloError) => {
  const errors = handleError({ error: apolloError, suppressNotificationError: true })
  return Object.keys(errors)
    .filter((key) => key !== FORM_ERROR)
    .map((key) => errors[key])
    .join('. ')
}

export function lotacaoAgendamentoToString({
  nomeEquipe,
  ineEquipe,
  nomeProfissional,
  nomeCbo,
}: {
  nomeEquipe?: string
  ineEquipe?: string
  nomeProfissional: string
  nomeCbo?: string
}): string {
  if (!!nomeProfissional) {
    const textoEquipe = !!nomeEquipe ? ` | ${nomeEquipe} - ${ineEquipe}` : ''
    const textoCbo = !!nomeCbo ? ` | ${nomeCbo.capitalize()}` : ''

    return `${nomeProfissional}${textoCbo}${textoEquipe}`
  } else {
    return ''
  }
}
