import { Calculation } from 'final-form-calculate'
import { OpcaoSelecionadaRelatorioGerencialEnum, UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'
import {} from 'view/relatorio/relatoriosgerenciais/common/model-relatorioGerencial'

import {
  calculatePeriodForDay,
  calculatePeriodForMonth,
  calculatePeriodForWeek,
} from '../../form/calculator-relatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialFormModel } from './model-modeloPersonalizadoRelatorioGerencial'

export const createModeloPersonalizadoRelatoriosGerenciaisPeriodoFormCalculator = (
  name: MetaRoot<ModeloPersonalizadoRelatorioGerencialFormModel>
): Calculation => ({
  field: [name.opcaoSelecionada.absolutePath(), name.unidadePeriodo.absolutePath()],
  updates: {
    [name.periodo.absolutePath()]: (
      _,
      {
        opcaoSelecionada: opcaoPeriodo,
        unidadePeriodo: unidadeTempo,
        periodo,
      }: ModeloPersonalizadoRelatorioGerencialFormModel
    ) => {
      const now = new Date()
      if (opcaoPeriodo !== OpcaoSelecionadaRelatorioGerencialEnum.OUTRO) {
        switch (unidadeTempo) {
          case UnidadePeriodoRelatorioGerencialEnum.DIA:
            return calculatePeriodForDay(now, opcaoPeriodo)

          case UnidadePeriodoRelatorioGerencialEnum.SEMANA:
            return calculatePeriodForWeek(now, opcaoPeriodo)

          case UnidadePeriodoRelatorioGerencialEnum.MES:
            return calculatePeriodForMonth(now, opcaoPeriodo)
        }
      } else return periodo
    },
  },
})
