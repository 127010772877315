import { TipoAtendimentoProfissional, TipoRegistroHistoricoClinico } from 'graphql/types.generated'
import React from 'react'

import { HistoricoDetalhesModel, IdadeOrigemAtendimento } from '../../../model/model-historico'
import { isFichaAtendimento, isTipoAtendimentoIndividual } from '../../../utils/historicoUtils'
import HistoricoAtendimentoDomiciliarPanel from './atendimento-domiciliar/HistoricoAtendimentoDomiciliarPanel'
import HistoricoConsultaPanel from './atendimento-individual/consulta/HistoricoConsultaPanel'
import HistoricoAtendimentoIndividualPanel from './atendimento-individual/dw/HistoricoAtendimentoIndividualPanel'
import HistoricoPreNatalPec from './atendimento-individual/pre-natal/HistoricoPreNatalPec'
import HistoricoPuericulturaPec from './atendimento-individual/puericultura/HistoricoPuericulturaPec'
import HistoricoPuerperioPec from './atendimento-individual/puerperio/HistoricoPuerperioPec'
import HistoricoAtendimentoOdontoPanel from './atendimento-odontologico/HistoricoAtendimentoOdontoPanel'
import HistoricoAtividadeColetivaPanel from './atividade-coletiva/HistoricoAtividadeColetivaPanel'
import HistoricoAvaliacaoElegibilidadePanel from './avaliacao-elegibilidade/HistoricoAvaliacaoElegibilidadePanel'
import { HistoricoCuidadoCompartilhadoPanel } from './cuidado-compartilhado/HistoricoCuidadoCompartilhadoPanel'
import HistoricoEscutaInicialPanel from './escuta-inicial/HistoricoEscutaInicialPanel'
import HistoricoMarcadoresConsumoAlimentarPanel from './marcador-consumo-alimentar/HistoricoMarcadoresConsumoAlimentarPanel'
import HistoricoProcedimentoPanel from './procedimento/HistoricoProcedimentoPanel'
import HistoricoVacinacaoPanel from './vacinacao/HistoricoVacinacaoPanel'
import HistoricoVisitaDomiciliarPanel from './visita-domiciliar/HistoricoVisitaDomiciliarPanel'
import HistoricoZikaPanel from './zika/HistoricoZikaPanel'

interface HistoricoDetalhesPanelProps {
  historicoAtendimento: HistoricoDetalhesModel
  idadeOrigemAtendimento: IdadeOrigemAtendimento
  dataNascimento: LocalDate
}

export default function HistoricoDetalhesPanel({
  historicoAtendimento,
  idadeOrigemAtendimento,
  dataNascimento,
}: HistoricoDetalhesPanelProps) {
  const isEscutaProcedimento =
    historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ESCUTA_INICIAL &&
    isFichaAtendimento(historicoAtendimento.origemAtendimento)

  const isEscutaInicial =
    historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ESCUTA_INICIAL ||
    historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.ESCUTA_INICIAL

  const isEscutaOdonto =
    historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ATENDIMENTO_ODONTOLOGICO &&
    historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.ESCUTA_INICIAL

  const isAtendOdonto =
    historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ATENDIMENTO_ODONTOLOGICO ||
    historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO

  return (
    <>
      {historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.CONSUMO_ALIMENTAR && (
        <HistoricoMarcadoresConsumoAlimentarPanel
          uuidRegistro={historicoAtendimento.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
        />
      )}
      {(historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.VACINACAO ||
        historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.VACINACAO) && (
        <HistoricoVacinacaoPanel
          idAtend={historicoAtendimento.idAtendRecente}
          uuidRegistro={historicoAtendimento.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
          isAtendRecente={historicoAtendimento.isAtendRecente}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
        />
      )}
      {historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ZIKA && (
        <HistoricoZikaPanel
          uuidRegistro={historicoAtendimento?.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
        />
      )}
      {historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.VISITA_DOMICILIAR && (
        <HistoricoVisitaDomiciliarPanel
          uuidRegistro={historicoAtendimento?.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
        />
      )}
      {(historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.AVALIACAO_ELEGIBILIDADE ||
        historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.AVALIACAO_ELEGIBILIDADE) && (
        <HistoricoAvaliacaoElegibilidadePanel
          isAtendRecente={historicoAtendimento.isAtendRecente}
          idAtend={historicoAtendimento.idAtendRecente}
          uuidRegistro={historicoAtendimento.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
        />
      )}
      {historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ATIVIDADE_COLETIVA && (
        <HistoricoAtividadeColetivaPanel
          uuidRegistro={historicoAtendimento?.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento?.cpfCnsCidadao}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
        />
      )}
      {(historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ATENDIMENTO_DOMICILIAR ||
        historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.ATENDIMENTO_DOMICILIAR) && (
        <HistoricoAtendimentoDomiciliarPanel
          isAtendRecente={historicoAtendimento.isAtendRecente}
          idAtend={historicoAtendimento.idAtendRecente}
          uuidRegistro={historicoAtendimento.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
        />
      )}
      {(historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS ||
        historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.OBSERVACAO ||
        historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.ATENDIMENTO_PROCEDIMENTOS ||
        isEscutaProcedimento) && (
        <HistoricoProcedimentoPanel
          idAtend={historicoAtendimento.idAtendRecente}
          uuidRegistro={historicoAtendimento.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
          isAtendRecente={historicoAtendimento.isAtendRecente}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
          dataNascimento={dataNascimento}
          isAtendObservacao={historicoAtendimento.hasObservacao}
        />
      )}
      {(isEscutaInicial || isEscutaOdonto) && (
        <HistoricoEscutaInicialPanel
          idAtend={historicoAtendimento.idAtendRecente}
          uuidRegistro={historicoAtendimento.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
          isAtendRecente={historicoAtendimento.isAtendRecente}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
        />
      )}
      {!isEscutaInicial && isAtendOdonto && (
        <HistoricoAtendimentoOdontoPanel
          idAtend={historicoAtendimento.idAtendRecente}
          uuidRegistro={historicoAtendimento.codigoUnicoRegistro}
          cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
          isAtendRecente={historicoAtendimento.isAtendRecente}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
          dataNascimento={dataNascimento}
        />
      )}
      {historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.PUERPERIO && (
        <HistoricoPuerperioPec
          idAtend={historicoAtendimento.idAtendRecente}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
          dataNascimento={dataNascimento}
          isAtendObservacao={historicoAtendimento.hasObservacao}
        />
      )}
      {historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.PRE_NATAL && (
        <HistoricoPreNatalPec
          idAtend={historicoAtendimento.idAtendRecente}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
          dataNascimento={dataNascimento}
          isAtendObservacao={historicoAtendimento.hasObservacao}
        />
      )}
      {historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.PUERICULTURA && (
        <HistoricoPuericulturaPec
          idAtend={historicoAtendimento.idAtendRecente}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
          dataNascimento={dataNascimento}
          isAtendObservacao={historicoAtendimento.hasObservacao}
        />
      )}
      {historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.CONSULTA && (
        <HistoricoConsultaPanel
          idAtend={historicoAtendimento.idAtendRecente}
          uuidRegistro={historicoAtendimento.codigoUnicoRegistro}
          isAtendRecente={historicoAtendimento.isAtendRecente}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
          dataNascimento={dataNascimento}
          isAtendObservacao={historicoAtendimento.hasObservacao}
        />
      )}
      {historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.CUIDADO_COMPARTILHADO && (
        <HistoricoCuidadoCompartilhadoPanel
          cuidadoCompartilhado={historicoAtendimento.cuidadoCompartilhado}
          cuidadoCompartilhadoEvolucao={historicoAtendimento.cuidadoCompartilhadoEvolucao}
          historicoDetalhes={historicoAtendimento}
          idadeOrigemAtendimento={idadeOrigemAtendimento}
        />
      )}
      {isFichaAtendimento(historicoAtendimento.origemAtendimento) &&
        isTipoAtendimentoIndividual(historicoAtendimento.tipoApresentacao) && (
          <HistoricoAtendimentoIndividualPanel
            idAtend={historicoAtendimento.idAtendRecente}
            uuidRegistro={historicoAtendimento.codigoUnicoRegistro}
            cpfOuCnsCidadao={historicoAtendimento.cpfCnsCidadao}
            isAtendRecente={historicoAtendimento.isAtendRecente}
            idadeOrigemAtendimento={idadeOrigemAtendimento}
            fichasConcatenadas={historicoAtendimento.fichasConcatenadas}
          />
        )}
    </>
  )
}
