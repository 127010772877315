/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, DateRange, Grid, Theme, useTheme, VFlow } from 'bold-ui'
import { ErrorField, Form, FormRenderProps } from 'components/form'
import {
  CiapCidSelectField,
  CiapCidSelectModel,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import {
  GrupoCondicaoSelectField,
  GrupoCondicaoSelectModel,
} from 'components/form/field/select/GrupoCondicaoSelectField'
import { useFlags } from 'config/useFlagsContext'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-calculate'
import {
  OpcaoSelecionadaRelatorioGerencialEnum,
  TipoModuloEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'
import { useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import { createCamposDisponiveisSelectFieldCalculations } from '../../common/calculator-camposDisponiveis'
import {
  CamposDisponiveisSelectFields,
  CamposDisponiveisSelectFieldsModel,
} from '../../common/CamposDisponiveisSelectFields'
import { ModeloPersonalizadoModel } from '../modelo-personalizado/model-modeloPersonalizado'
import { ModeloPersonalizadoSelectField } from '../modelo-personalizado/ModeloPersonalizadoSelectField'
import { createRelatoriosGerenciaisPeriodoFormCalculation } from './calculator-relatorioGerencial'
import { RelatorioGerencialPeriodoLabel } from './RelatorioGerencialPeriodoLabel'
import { RelatorioGerencialPeriodoRadioField } from './RelatorioGerencialPeriodoRadioField'
import { RelatorioGerencialUnidadePeriodoSelectField } from './RelatorioGerencialUnidadePeriodoSelectField'
import { relatorioGerencialFiltrosFormValidator } from './validator-relatorioGerencial'

export interface RelatorioGerencialFiltrosFormModel {
  unidade: UnidadePeriodoRelatorioGerencialEnum
  opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum
  periodo?: DateRange
  ciapsCids: CiapCidSelectModel[]
  gruposCondicoesPrioritarios: GrupoCondicaoSelectModel[]
  modelo?: ModeloPersonalizadoModel
  camposDisponiveis?: Partial<CamposDisponiveisSelectFieldsModel>
}

const meta = metaPath<RelatorioGerencialFiltrosFormModel>()

export interface RelatorioGerencialFiltrosFormProps<T = any> {
  loading: boolean
  hasFiltroCiapCidGrupoCondicoes?: boolean
  filtrosLocalStorage?: RelatorioGerencialFiltrosFormModel
  onSubmit: (filtros: RelatorioGerencialFiltrosFormModel) => void
  setShouldRenderChildren: (shouldRenderChildren: boolean) => void
  setModeloSelecionado?: (modelo: ModeloPersonalizadoModel) => void
  modulo: TipoModuloEnum
}

export function RelatorioGerencialFiltrosForm<T = any>(props: RelatorioGerencialFiltrosFormProps<T>) {
  const {
    loading,
    hasFiltroCiapCidGrupoCondicoes = false,
    onSubmit,
    setShouldRenderChildren,
    setModeloSelecionado,
    modulo,
    filtrosLocalStorage,
  } = props

  const { MODELOS_PERSONALIZADOS_ENABLED } = useFlags()
  const initialValues = useMemo<RelatorioGerencialFiltrosFormModel>(() => {
    if (filtrosLocalStorage) {
      return filtrosLocalStorage
    } else
      return {
        opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA,
        unidade: UnidadePeriodoRelatorioGerencialEnum.MES,
        ciapsCids: [],
        gruposCondicoesPrioritarios: [],
        periodo: null,
        modelo: null,
      }
  }, [filtrosLocalStorage])

  const handleChange = () => {
    setShouldRenderChildren(false)
  }

  const handleChangeModelo = (modelo: ModeloPersonalizadoModel) => {
    setShouldRenderChildren(false)
    setModeloSelecionado?.(modelo)
  }

  const decorators = useMemo(
    () => [
      createDecorator(
        createRelatoriosGerenciaisPeriodoFormCalculation(meta),
        // TODO (eldorado #19208) adequar com form da modal
        ...(MODELOS_PERSONALIZADOS_ENABLED ? createCamposDisponiveisSelectFieldCalculations(meta) : [])
      ),
    ],
    [MODELOS_PERSONALIZADOS_ENABLED]
  )
  const theme = useTheme()
  const styles = createStyles(theme)

  const renderForm = ({ handleSubmit, values }: FormRenderProps<RelatorioGerencialFiltrosFormModel>) => {
    return (
      <VFlow>
        {MODELOS_PERSONALIZADOS_ENABLED && (
          <VFlow>
            <ModeloPersonalizadoSelectField tipoModulo={modulo} name={meta.modelo} onChange={handleChangeModelo} />
            {/* TODO (eldorado #19208) passar pro form da modal */}
            <CamposDisponiveisSelectFields meta={meta.camposDisponiveis} />
          </VFlow>
        )}
        <RelatorioGerencialPeriodoLabel />
        <ErrorField name={FORM_ERROR} />
        <VFlow css={styles.periodoContainer}>
          <Grid wrap alignItems='center'>
            <Cell size={6}>
              <RelatorioGerencialUnidadePeriodoSelectField
                name={meta.unidade}
                disabled={loading}
                style={styles.unidadePeriodoSelectField}
                onChange={handleChange}
              />
            </Cell>
            <Cell size={12}>
              <RelatorioGerencialPeriodoRadioField
                nameOpcaoSelecionada={meta.opcaoSelecionada}
                namePeriodo={meta.periodo}
                opcaoSelecionadaValue={values?.opcaoSelecionada}
                unidadeTempoValue={values?.unidade}
                disabled={loading}
                onChange={handleChange}
              />
            </Cell>
          </Grid>
        </VFlow>
        {hasFiltroCiapCidGrupoCondicoes && (
          <Grid alignItems='stretch' direction='row'>
            <Cell size={6}>
              <CiapCidSelectField
                label='CIAP2 e CID10'
                placeholder='Selecione outros CIAP2 e CID10'
                name={meta.ciapsCids}
                disabled={loading}
                multiple
                onChange={handleChange}
              />
            </Cell>
            <Cell size={6}>
              <GrupoCondicaoSelectField
                label='Grupos de condições prioritários'
                placeholder='Selecione grupos de condições prioritários'
                name={meta.gruposCondicoesPrioritarios}
                disabled={loading}
                multiple
                onChange={handleChange}
              />
            </Cell>
          </Grid>
        )}
        <Button kind='primary' size='small' type='submit' disabled={loading} onClick={handleSubmit} loading={loading}>
          Consultar
        </Button>
      </VFlow>
    )
  }

  return (
    <Form<RelatorioGerencialFiltrosFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      validate={relatorioGerencialFiltrosFormValidator(MODELOS_PERSONALIZADOS_ENABLED)}
      initialValues={initialValues}
      decorators={decorators}
    />
  )
}

const createStyles = (theme: Theme) => ({
  periodoContainer: css`
    position: relative;
    padding: 1rem;
    border: 1px solid ${theme.pallete.gray.c80};
  `,
  unidadePeriodoSelectField: css`
    position: absolute;
    margin-left: -0.5rem;
    top: -0.7rem;
    padding: 0 0.5rem;
    background-color: ${theme.pallete.surface.main};
  `,
})
