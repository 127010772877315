import { Cell, Grid, Text, VFlow } from 'bold-ui'
import { CampoRelatorioGerencialSelectField } from 'components/form/field/select/CampoRelatorioGerencialSelectField/CampoRelatorioGerencialSelectField'
import {
  AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldDocument,
  CategoriaProfissionalRelatorioGerencialSelectFieldDocument,
  CboRelatorioGerencialSelectFieldDocument,
  CondutasRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  DiasDaSemanaRelatorioGerencialSelectFieldDocument,
  EquipeRelatorioGerencialSelectFieldDocument,
  FaixaEtariaRelatorioGerencialSelectFieldDocument,
  HorariosRelatorioGerencialSelectFieldDocument,
  MesesRelatorioGerencialSelectFieldDocument,
  MunicipioRelatorioGerencialSelectFieldDocument,
  PrimeirasRespostasRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  PrioridadesRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  ProfissionalRelatorioGerencialSelectFieldDocument,
  RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  SexosRelatorioGerencialSelectFieldDocument,
  TempoEntreAgendamentoEConsultaSelectFieldDocument,
  TemposDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  TemposDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  TipoProducaoRelatorioGerencialSelectFieldDocument,
  TiposDeAtendimentoRelatorioGerencialAtendimentoSelectFieldDocument,
  TiposDeConsultaRelatorioGerencialAtendimentoSelectFieldDocument,
  TiposDeFaltaRelatorioGerencialAbsenteismoSelectFieldDocument,
  TurnosRelatorioGerencialSelectFieldDocument,
  UnidadeSaudeRelatorioGerencialSelectFieldDocument,
} from 'graphql/hooks.generated'
import {
  AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery,
  AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryVariables,
  CategoriaProfissionalRelatorioGerencialSelectFieldQuery,
  CategoriaProfissionalRelatorioGerencialSelectFieldQueryVariables,
  CboRelatorioGerencialSelectFieldQuery,
  CboRelatorioGerencialSelectFieldQueryVariables,
  CondutasRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  CondutasRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables,
  DiasDaSemanaRelatorioGerencialSelectFieldQuery,
  DiasDaSemanaRelatorioGerencialSelectFieldQueryVariables,
  EquipeRelatorioGerencialSelectFieldQuery,
  EquipeRelatorioGerencialSelectFieldQueryVariables,
  FaixaEtariaRelatorioGerencialSelectFieldQuery,
  FaixaEtariaRelatorioGerencialSelectFieldQueryVariables,
  HorariosRelatorioGerencialSelectFieldQuery,
  HorariosRelatorioGerencialSelectFieldQueryVariables,
  MesesRelatorioGerencialSelectFieldQuery,
  MesesRelatorioGerencialSelectFieldQueryVariables,
  MunicipioRelatorioGerencialSelectFieldQuery,
  MunicipioRelatorioGerencialSelectFieldQueryVariables,
  PrimeirasRespostasRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  PrimeirasRespostasRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables,
  PrioridadesRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  PrioridadesRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables,
  ProfissionalRelatorioGerencialSelectFieldQuery,
  ProfissionalRelatorioGerencialSelectFieldQueryVariables,
  RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables,
  SexosRelatorioGerencialSelectFieldQuery,
  SexosRelatorioGerencialSelectFieldQueryVariables,
  TempoEntreAgendamentoEConsultaSelectFieldQuery,
  TempoEntreAgendamentoEConsultaSelectFieldQueryVariables,
  TemposDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  TemposDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables,
  TemposDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  TemposDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables,
  TipoProducaoRelatorioGerencialSelectFieldQuery,
  TipoProducaoRelatorioGerencialSelectFieldQueryVariables,
  TiposDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery,
  TiposDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryVariables,
  TiposDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery,
  TiposDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryVariables,
  TiposDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery,
  TiposDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryVariables,
  TurnosRelatorioGerencialSelectFieldQuery,
  TurnosRelatorioGerencialSelectFieldQueryVariables,
  UnidadeSaudeRelatorioGerencialSelectFieldQuery,
  UnidadeSaudeRelatorioGerencialSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import { MetaRoot } from 'util/metaPath'

import { RelatorioGerencialAbsenteismoModel } from '../absenteismo/model-relatorioGerencialAbsenteismo'
import { RelatorioGerencialAtendimentoProfissionalModel } from '../atendimentos/model-relatorioGerencialAtendimentoProfissional'
import { RelatorioGerencialCuidadoCompartilhadoModel } from '../cuidado-compartilhado/model-relatorioGerencialCuidadoCompartilhado'

export type CamposDisponiveisSelectFieldsModel = {
  [key in
    | keyof RelatorioGerencialAtendimentoProfissionalModel
    | keyof RelatorioGerencialAbsenteismoModel
    | keyof RelatorioGerencialCuidadoCompartilhadoModel]: string[]
}

interface CamposDisponiveisSelectFieldsProps {
  meta: MetaRoot<CamposDisponiveisSelectFieldsModel>
}

const emptyVariables = () => ({})
const filterVariables = (input: string) => ({ input })

export function CamposDisponiveisSelectFields(props: CamposDisponiveisSelectFieldsProps) {
  const { meta } = props

  return (
    <Grid>
      <Cell size={4}>
        <VFlow>
          <Text variant='h4'>Campos disponíveis</Text>
          <VFlow vSpacing={0.5}>
            <Text variant='h5'>Campos comuns:</Text>
            <CampoRelatorioGerencialSelectField<
              HorariosRelatorioGerencialSelectFieldQuery,
              HorariosRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.hora}
              label='Hora'
              queryDocument={HorariosRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.horariosRelatorioGerencial}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              TurnosRelatorioGerencialSelectFieldQuery,
              TurnosRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.turno}
              label='Turno'
              queryDocument={TurnosRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.turnosRelatorioGerencial}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              DiasDaSemanaRelatorioGerencialSelectFieldQuery,
              DiasDaSemanaRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.diaDaSemana}
              label='Dia da semana'
              queryDocument={DiasDaSemanaRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.diasDaSemanaRelatorioGerencial}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              MesesRelatorioGerencialSelectFieldQuery,
              MesesRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.mes}
              label='Mês'
              queryDocument={MesesRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.mesesRelatorioGerencial}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              SexosRelatorioGerencialSelectFieldQuery,
              SexosRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.sexo}
              label='Sexo'
              queryDocument={SexosRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.sexosRelatorioGerencial}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              TipoProducaoRelatorioGerencialSelectFieldQuery,
              TipoProducaoRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.producoes}
              label='Tipo produção'
              queryDocument={TipoProducaoRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.tiposProducaoRelatorioGerencial}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              FaixaEtariaRelatorioGerencialSelectFieldQuery,
              FaixaEtariaRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.faixaEtaria}
              label='Faixa etaria'
              queryDocument={FaixaEtariaRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.faixasEtariaRelatorioGerencial}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              CategoriaProfissionalRelatorioGerencialSelectFieldQuery,
              CategoriaProfissionalRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.categoriaProfissional}
              label='Categoria profissional'
              queryDocument={CategoriaProfissionalRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.categoriasProfissionalRelatorioGerencial}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              MunicipioRelatorioGerencialSelectFieldQuery,
              MunicipioRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.municipio}
              label='Município'
              queryDocument={MunicipioRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.municipiosRelatorioGerencial}
              variables={filterVariables}
              refetchOnFilterChange
            />
            <CampoRelatorioGerencialSelectField<
              UnidadeSaudeRelatorioGerencialSelectFieldQuery,
              UnidadeSaudeRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.unidadeSaude}
              label='Unidade de saúde'
              queryDocument={UnidadeSaudeRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.unidadesSaudeRelatorioGerencial}
              variables={filterVariables}
              refetchOnFilterChange
            />
            <CampoRelatorioGerencialSelectField<
              EquipeRelatorioGerencialSelectFieldQuery,
              EquipeRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.equipe}
              label='Equipe'
              queryDocument={EquipeRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.equipesRelatorioGerencial}
              variables={filterVariables}
              refetchOnFilterChange
            />
            <CampoRelatorioGerencialSelectField<
              CboRelatorioGerencialSelectFieldQuery,
              CboRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.cbo}
              label='CBO'
              queryDocument={CboRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.cbosRelatorioGerencial}
              variables={filterVariables}
              refetchOnFilterChange
            />
            <CampoRelatorioGerencialSelectField<
              ProfissionalRelatorioGerencialSelectFieldQuery,
              ProfissionalRelatorioGerencialSelectFieldQueryVariables
            >
              name={meta.nomeProfissional}
              label='Profissional'
              queryDocument={ProfissionalRelatorioGerencialSelectFieldDocument}
              extractItems={(data) => data?.profissionaisRelatorioGerencial}
              variables={filterVariables}
              refetchOnFilterChange
            />
          </VFlow>

          <VFlow vSpacing={0.5}>
            <Text variant='h5'>Campos específicos do atendimento:</Text>
            <CampoRelatorioGerencialSelectField<
              TiposDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery,
              TiposDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryVariables
            >
              name={meta.tipoAtendimento}
              label='Tipo de atendimento'
              queryDocument={TiposDeAtendimentoRelatorioGerencialAtendimentoSelectFieldDocument}
              extractItems={(data) => data?.tiposDeAtendimentoRelatorioGerencialAtendimento}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              TiposDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery,
              TiposDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryVariables
            >
              name={meta.tipoConsulta}
              label='Tipo de consulta'
              queryDocument={TiposDeConsultaRelatorioGerencialAtendimentoSelectFieldDocument}
              extractItems={(data) => data?.tiposDeConsultaRelatorioGerencialAtendimento}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery,
              AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryVariables
            >
              name={meta.atendimentoCompartilhadoLotacao}
              label='Atendimento compartilhado'
              queryDocument={AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldDocument}
              extractItems={(data) => data?.atendimentoCompartilhadoRelatorioGerencialAtendimento}
              variables={emptyVariables}
            />
          </VFlow>

          <VFlow vSpacing={0.5}>
            <Text variant='h5'>Campos específicos do cuidado compartilhado:</Text>
            <CampoRelatorioGerencialSelectField<
              CondutasRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
              CondutasRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
            >
              name={meta.conduta}
              label='Conduta'
              queryDocument={CondutasRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument}
              extractItems={(data) => data?.condutasRelatorioGerencialCuidadoCompartilhado}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              PrimeirasRespostasRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
              PrimeirasRespostasRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
            >
              name={meta.primeiraResposta}
              label='Primeira resposta'
              queryDocument={PrimeirasRespostasRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument}
              extractItems={(data) => data?.primeirasRespostasRelatorioGerencialCuidadoCompartilhado}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              PrioridadesRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
              PrioridadesRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
            >
              name={meta.prioridade}
              label='Prioridade'
              queryDocument={PrioridadesRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument}
              extractItems={(data) => data?.prioridadesRelatorioGerencialCuidadoCompartilhado}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
              RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
            >
              name={meta.realizadoPor}
              label='Realizado por'
              queryDocument={RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument}
              extractItems={(data) => data?.realizadoPorRelatorioGerencialCuidadoCompartilhado}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              TemposDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
              TemposDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
            >
              name={meta.tempoEspera}
              label='Tempo de espera'
              queryDocument={TemposDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument}
              extractItems={(data) => data?.temposDeEsperaRelatorioGerencialCuidadoCompartilhado}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              TemposDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
              TemposDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
            >
              name={meta.tempoResposta}
              label='Tempo de resposta'
              queryDocument={TemposDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument}
              extractItems={(data) => data?.temposDeRespostaRelatorioGerencialCuidadoCompartilhado}
              variables={emptyVariables}
            />
          </VFlow>

          <VFlow vSpacing={0.5}>
            <Text variant='h5'>Campos específicos do absenteísmo:</Text>
            <CampoRelatorioGerencialSelectField<
              TiposDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery,
              TiposDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryVariables
            >
              name={meta.nomeSituacaoAgendamento}
              label='Tipo de falta'
              queryDocument={TiposDeFaltaRelatorioGerencialAbsenteismoSelectFieldDocument}
              extractItems={(data) => data?.tiposDeFaltaRelatorioGerencialAbsenteismo}
              variables={emptyVariables}
            />
            <CampoRelatorioGerencialSelectField<
              TempoEntreAgendamentoEConsultaSelectFieldQuery,
              TempoEntreAgendamentoEConsultaSelectFieldQueryVariables
            >
              name={meta.intervaloAgendamento}
              label='Tempo entre agendamento e consulta'
              queryDocument={TempoEntreAgendamentoEConsultaSelectFieldDocument}
              extractItems={(data) => data?.tempoEntreAgendamentoEConsultaRelatorioGerencialAbsenteismo}
              variables={emptyVariables}
            />
          </VFlow>
        </VFlow>
      </Cell>
    </Grid>
  )
}
