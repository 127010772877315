import { simNaoEnumToBoolean } from 'components/form/field/SimNaoRadioGroupField'
import { CuidadoCompartilhadoPlanoInput } from 'graphql/types.generated'

import { CuidadoCompartilhadoPlanoModel } from './model-cuidadocompartilhado'

export const convertCuidadoCompartilhadoPlanoToInput = (
  items: CuidadoCompartilhadoPlanoModel[]
): CuidadoCompartilhadoPlanoInput[] => {
  return (
    items?.map((item) => ({
      lotacaoExecutanteId: item.lotacao?.id,
      discussao: item.discussao,
      classificacaoPrioridade: item.prioridade,
      ciap: item.problemasECondicoes.ciap?.id,
      cid10: item.problemasECondicoes.cid10?.id,
      cidadaoAceitaAtendTic: simNaoEnumToBoolean[item.cidadaoAceitaAtendTic],
    })) ?? []
  )
}
