import {
  conditionalRequired,
  createValidator,
  empty,
  ErrorObject,
  oneRequired,
  required,
  requiredRichText,
  richTextMaxLength,
} from 'util/validation'

import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'

export const cuidadoCompartilhadoFormValidator = (isSomenteCiap: boolean, unidadeSaudeId: ID, flag: boolean) => {
  return createValidator<CuidadoCompartilhadoPlanoModel>(
    {
      cbo: [required],
      prioridade: [required],
      discussao: [requiredRichText, richTextMaxLength(1000)],
    },
    (values: CuidadoCompartilhadoPlanoModel, errors: ErrorObject<CuidadoCompartilhadoPlanoModel>) => {
      const isCuidadoCompartilhado = true // TODO(@Supernova): aguardando mergear PR#19125 (https://github.com/laboratoriobridge/pec/pull/19125)
      const isGuiaEncaminhamento = false // TODO(@Supernova): aguardando mergear PR#19125 (https://github.com/laboratoriobridge/pec/pull/19125)
      const isEMulti = !flag || (isCuidadoCompartilhado && unidadeSaudeId === values.unidadeSaude?.id)
      const isTicRequired = (flag && isCuidadoCompartilhado) || !flag

      if (isCuidadoCompartilhado) {
        if (isEMulti) errors.lotacao = required(values.lotacao)
        if (flag) errors.unidadeSaude = required(values.unidadeSaude)
        errors.cidadaoAceitaAtendTic = conditionalRequired(isTicRequired)(values.cidadaoAceitaAtendTic)
      } else if (isGuiaEncaminhamento) {
        errors.unidadeSaude = empty(values.unidadeSaude)
        errors.lotacao = empty(values.lotacao)
        errors.cidadaoAceitaAtendTic = empty(values.cidadaoAceitaAtendTic)
      }

      if (isSomenteCiap) {
        errors.problemasECondicoes = {
          ciap: required(values.problemasECondicoes?.ciap),
          cid10: empty(values.problemasECondicoes?.cid10),
        }
      } else {
        errors.problemasECondicoes = oneRequired([values.problemasECondicoes?.ciap, values.problemasECondicoes?.cid10])
      }

      return errors
    }
  )
}
