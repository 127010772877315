import { PivotTableInitialValues } from 'components/pivot-table/PivotTable'
import {
  ModeloPersonalizadoRelatorioGerencial,
  OpcaoSelecionadaRelatorioGerencialEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'

import { KeyMapping } from '../../common/keyMapping-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../form/RelatorioGerencialFiltrosForm'

export function modeloPersonalizadoToPivotTableProps<T>(
  keyMapping: Map<string, KeyMapping>,
  modelo?: ModeloPersonalizadoRelatorioGerencial
): PivotTableInitialValues<T> {
  // TODO (eldorado #19904) remover os casts pra any
  return modelo
    ? {
        fields: modelo.modeloPersonalizado.campos
          .filter((value) => !isUndefinedOrNull(keyMapping.get(value.key)?.visibility))
          .map((value) => ({ filters: value.values, key: value.key, origin: value.posicao as any } as any)),
        aggregatorId: modelo.modeloPersonalizado.aggregator as any,
      }
    : null
}

export function modeloPersonalizadoToTableWrapperProps(
  modelo?: ModeloPersonalizadoRelatorioGerencial
): RelatorioGerencialFiltrosFormModel {
  return {
    ciapsCids: [],
    gruposCondicoesPrioritarios: [],
    opcaoSelecionada: modelo?.modeloPersonalizado.opcaoSelecionada ?? OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA,
    unidade: modelo?.modeloPersonalizado.unidadePeriodo ?? UnidadePeriodoRelatorioGerencialEnum.MES,
    modelo: {
      id: modelo?.id,
      nome: modelo?.nome,
    },
    periodo:
      modelo?.modeloPersonalizado.opcaoSelecionada === OpcaoSelecionadaRelatorioGerencialEnum.OUTRO &&
      modelo?.modeloPersonalizado.periodo,
  }
}
