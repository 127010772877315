import { useFlagsQuery } from 'graphql/hooks.generated'
import React, { useContext } from 'react'

export interface FlagsContextModel {
  UNIFICACAO_BASE_ENABLED: Boolean
  GESTAO_CADASTRO_UNIFICACAO_BASE_ENABLED: Boolean
  TERRITORIO_ENABLED: Boolean
  VISUALIZACAO_TERRITORIO_ENABLED: Boolean
  BUSCA_ATIVA_VACINACAO_ENABLED: Boolean
  API_DADOS_DW_ENABLED: Boolean
  ENVIO_RAC_ENABLED: Boolean
  ENVIO_RPM_ENABLED: Boolean
  ENVIO_VACINA_ENABLED: Boolean
  ENVIO_RNDS_ENABLED: Boolean
  CUIDADO_COMPARTILHADO_ENABLED: boolean
  HISTORICO_CUIDADO_COMPARTILHADO_ENABLED: boolean
  PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED: boolean
  PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED: boolean
  COOKIE_ENABLED: Boolean
  NPS_ENABLED: Boolean
  PILOTO_ATESTADO_DIGITAL_ENABLED: boolean
  PILOTO_VIDEOCHAMADA_2_ENABLED: Boolean
  PRESCRICAO_CONTROLADA: Boolean
  PILOTO_VIDEOCHAMADA_3_ENABLED: Boolean
  IMPRESSAO_ESCUTA_INICIAL_ENABLED: Boolean
  PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED: Boolean
  GARANTIA_ACESSO_ENABLED: Boolean
  PILOTO_VINCULACAO_SERVICO_1_ENABLED: Boolean
  PRESCRICAO_TURNO: Boolean
  MODELOS_PERSONALIZADOS_ENABLED: boolean
  ATUALIZACAO_SIGTAP_ENABLED: Boolean
}

interface FlagsProviderProps {
  children: React.ReactNode
}

export const FlagsContext = React.createContext<FlagsContextModel>(undefined)

export function FlagsProvider({ children }: FlagsProviderProps) {
  const {
    data: { info: { flags } = {} },
  } = useFlagsQuery({
    fetchPolicy: 'cache-first',
  })

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
}

export const useFlags = () => useContext(FlagsContext)
