import { PivotTableProps } from 'components/pivot-table/PivotTable'

import { KeyMapping, keyMappings, VisibilidadeModeloPersonalizadoEnum } from '../common/keyMapping-relatorioGerencial'
import { RelatorioGerencialVacinacaoModel } from './model-relatorioGerencialVacinacao'

const doseKeyMapping: KeyMapping = {
  keyName: 'Dose',
  formatter: (value: string) => value.capitalize(),
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
}

const gestanteKeyMapping: KeyMapping = {
  keyName: 'Gestante',
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
}

const puerperaKeyMapping: KeyMapping = {
  keyName: 'Puérpera',
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
}

const estrategiaVacinacaoKeyMapping: KeyMapping = {
  keyName: 'Estratégia',
  formatter: (s: string) => s.capitalize(),
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
}

const viajanteKeyMapping: KeyMapping = {
  keyName: 'Viajante',
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
}

const imunobiologicoKeyMapping: KeyMapping = {
  keyName: 'Imunobiológico',
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
}

export const vacinacaoKeyMapping: PivotTableProps<RelatorioGerencialVacinacaoModel>['keyMapping'] = new Map<
  keyof RelatorioGerencialVacinacaoModel,
  KeyMapping
>([
  ['dose', doseKeyMapping],
  ['imunobiologico', imunobiologicoKeyMapping],
  ['municipio', keyMappings.municipio],
  ['unidadeSaude', keyMappings.unidadeSaude],
  ['nomeProfissional', keyMappings.nomeProfissional],
  ['categoriaProfissional', keyMappings.categoriaProfissional],
  ['equipe', keyMappings.equipe],
  ['turno', keyMappings.turno],
  ['sexo', keyMappings.sexo],
  ['dia', keyMappings.dia],
  ['mes', keyMappings.mes],
  ['hora', keyMappings.hora],
  ['semana', keyMappings.semana],
  ['diaSemana', keyMappings.diaDaSemana],
  ['faixaEtaria', keyMappings.faixaEtaria],
  ['gestante', gestanteKeyMapping],
  ['puerpera', puerperaKeyMapping],
  ['estrategiaVacinacao', estrategiaVacinacaoKeyMapping],
  ['viajante', viajanteKeyMapping],
  ['cbo', keyMappings.cbo],
  ['producoes', keyMappings.producoes],
])
