import { FORM_ERROR } from 'final-form'
import { UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import {
  createValidator,
  empty,
  maxLength,
  maxWeeksPeriod,
  maxYearsPeriod,
  periodBeforeEqualToday,
  required,
  requiredPeriod,
} from 'util/validation'
import { isValid } from 'util/validation/Util'
import {
  RELATORIOS_GERENCIAIS_PERIOD_MAX_WEEKS,
  RELATORIOS_GERENCIAIS_PERIOD_MAX_YEARS,
} from 'view/relatorio/relatoriosgerenciais/common/model-relatorioGerencial'

import {
  ModeloPersonalizadoRelatorioGerencialCampoModel,
  ModeloPersonalizadoRelatorioGerencialFormModel,
} from './model-modeloPersonalizadoRelatorioGerencial'

export const modeloPersonalizadoRelatorioGerencialFormValidator = (hasFiltroCiapCid: boolean) =>
  createValidator<ModeloPersonalizadoRelatorioGerencialFormModel>(
    {
      nome: [required, maxLength(255)],
      aggregator: [required],
      opcaoSelecionada: [required],
      unidadePeriodo: [required],
      modulo: [required],
      periodo: [requiredPeriod, periodBeforeEqualToday],
    },
    (value, errors) => {
      if (value.campos?.length === 0) {
        errors.campos = 'Obrigatório adicionar uma coluna/linha'
      }
      if (isValid(errors.periodo)) {
        if (value.unidadePeriodo === UnidadePeriodoRelatorioGerencialEnum.SEMANA) {
          errors[FORM_ERROR] = maxWeeksPeriod(value.periodo, RELATORIOS_GERENCIAIS_PERIOD_MAX_WEEKS)
        } else {
          errors[FORM_ERROR] = maxYearsPeriod(value.periodo, RELATORIOS_GERENCIAIS_PERIOD_MAX_YEARS)
        }
      }
      if (!hasFiltroCiapCid) {
        errors.ciapsCids = empty(value.ciapsCids)
        errors.gruposCondicoes = empty(value.gruposCondicoes)
      }
      return errors
    }
  )

export const modeloPersonalizadoRelatorioGerencialCamposFormValidator = (
  campos: ModeloPersonalizadoRelatorioGerencialCampoModel[]
) =>
  createValidator<ModeloPersonalizadoRelatorioGerencialCampoModel>(
    {
      key: [required],
    },
    (formValues, errors) => {
      if (formValues?.key && campos?.filter((item) => item.key === formValues.key).isNotEmpty()) {
        errors.key = 'Esse campo já foi inserido no formulário'
      }
      return errors
    }
  )
