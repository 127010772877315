/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, HFlow, Icon, isEmpty, Tooltip, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { CboSelectField, ErrorField, RichTextField } from 'components/form'
import { CiapSelectField } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { SimNaoRadioGroupField } from 'components/form/field/SimNaoRadioGroupField'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { Cbo, CiapCapituloEnum, UnidadeSaude } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { random } from 'lodash'
import { Fragment, useEffect, useMemo } from 'react'
import { useField } from 'react-final-form'
import { isUndefinedOrNull } from 'util/checks'
import { MetaRoot } from 'util/metaPath'

import { ClassificacaoPrioridadeField } from '../components/ClassificacaoPrioridadeField'
import { EstabelecimentosCuidadoCompartilhadoSelectField } from '../components/EstabelecimentosCuidadoCompartilhadoSelectField'
import { CuidadoCompartilhadoPlanoModel, TipoCompartilhamentoCuidadoModel } from '../model-cuidadocompartilhado'

interface CuidadoCompartilhadoFormFieldsProps {
  path: MetaRoot<CuidadoCompartilhadoPlanoModel>
  disabled?: boolean
  setDisabled?(isDisabled: boolean): void
  setRegistradoVia?(registradoVia: TipoCompartilhamentoCuidadoModel): void
}

export const CuidadoCompartilhadoFormFields = (props: CuidadoCompartilhadoFormFieldsProps) => {
  const { path, disabled = false, setDisabled, setRegistradoVia } = props

  const {
    cidadao,
    permissoes: { somenteCiap },
  } = useAtendimentoContext()
  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const excludeProfissionaisIds = [profissional.id]
  const isIdentidadeGeneroEmpty = isEmpty(cidadao?.identidadeGeneroDbEnum)
  const cidadaoSexo = isIdentidadeGeneroEmpty ? cidadao?.sexo : null

  const {
    input: { value: unidadeSaudeValue },
  } = useField<UnidadeSaude>(path.unidadeSaude.absolutePath(), {
    subscription: {
      value: true,
    },
  })
  const {
    input: { value: cboValue },
  } = useField<Cbo>(path.cbo.absolutePath(), {
    subscription: {
      value: true,
    },
  })

  const unidadeSaudeId = unidadeSaudeValue?.id
  const cboId = cboValue?.id
  const temporario = useMemo(() => cboId !== null && random(1, 10), [cboId]) // TODO(@Supernova): aguardando mergear PR#19125 (https://github.com/laboratoriobridge/pec/pull/19125)
  const isCuidadoCompartilhado = useMemo(() => !disabled && temporario >= 5, [disabled, temporario]) // TODO(@Supernova): aguardando mergear PR#19125 (https://github.com/laboratoriobridge/pec/pull/19125)
  const isGuiaEncaminhamento = useMemo(() => !disabled && temporario < 5, [disabled, temporario]) // TODO(@Supernova): aguardando mergear PR#19125 (https://github.com/laboratoriobridge/pec/pull/19125)
  const isEMulti =
    !PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED || (isCuidadoCompartilhado && acesso.unidadeSaude.id === unidadeSaudeId)
  const equipeId = isEMulti ? acesso.equipe?.id : null

  useEffect(() => {
    setDisabled?.(isUndefinedOrNull(cboId))
    setRegistradoVia?.(
      isCuidadoCompartilhado ? 'CUIDADO_COMPARTILHADO' : isGuiaEncaminhamento ? 'GUIA_ENCAMINHAMENTO' : null
    )
  }, [cboId, isCuidadoCompartilhado, isGuiaEncaminhamento, setDisabled, setRegistradoVia])

  return (
    <Grid>
      <Cell size={6}>
        {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED ? (
          <EstabelecimentosCuidadoCompartilhadoSelectField
            name={path.unidadeSaude}
            label='Unidade de saúde/Serviço de referência'
            cboId={cboId}
            isVinculado={isCuidadoCompartilhado}
            disabled={disabled || (isGuiaEncaminhamento && PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED)}
            required={isCuidadoCompartilhado}
          />
        ) : (
          <CboSelectField
            name={path.cbo}
            label='CBO'
            mostrarIndisponivelLotacao={false}
            apenasEquipeCuidadoCompartilhado
            equipeVinculadaId={equipeId}
            nivelSuperior
            excludeProfissionaisIds={excludeProfissionaisIds}
          />
        )}
      </Cell>
      <Cell size={6}>
        <FormControl label='Profissional' required={isEMulti}>
          <HFlow hSpacing={0.5} alignItems='baseline' style={styles.hFlow}>
            <LotacaoAndEstagioSelectField
              name={path.lotacao}
              unidadeSaudeId={unidadeSaudeId}
              cboId={cboId}
              canWriteAtendimento={false}
              nivelSuperior
              excludeProfissionaisIds={excludeProfissionaisIds}
              apenasEquipeCuidadoCompartilhado={isEMulti}
              equipeVinculadaId={equipeId}
              required={isEMulti}
              disabled={disabled || (isGuiaEncaminhamento && PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED)}
            />
            {isEMulti && (
              <Tooltip
                text='O campo só traz profissionais de equipes NASF e eMulti vinculados a essa unidade.'
                placement='top'
              >
                <Icon icon='infoCircleFilled' fill='normal' size={1} style={styles.iconTooltip} />
              </Tooltip>
            )}
          </HFlow>
        </FormControl>
      </Cell>
      {!disabled && (
        <Fragment>
          <Cell size={12}>
            <VFlow vSpacing={0.5}>
              <FormControl label='Problemas/condições' required>
                <Grid>
                  <Cell size={6}>
                    <CiapSelectField
                      name={path.problemasECondicoes.ciap}
                      label='CIAP 2'
                      idadeCidadaoEmAnos={cidadao.idadeEmAnos}
                      sexo={isEmpty(cidadao.identidadeGeneroDbEnum) ? cidadaoSexo : undefined}
                      capitulosExcluidos={[CiapCapituloEnum.PROCEDIMENTOS]}
                      excluirCIAPsDAB
                      menuMinWidth={620}
                    />
                  </Cell>
                  {!somenteCiap && (
                    <Cell size={6}>
                      <CidSelectField
                        name={path.problemasECondicoes.cid10}
                        label='CID 10'
                        renderGrupoCiapRelacionada={false}
                        idadeCidadaoEmAnos={cidadao.idadeEmAnos}
                        sexo={cidadaoSexo}
                      />
                    </Cell>
                  )}
                </Grid>
              </FormControl>
              <ErrorField name={path.problemasECondicoes} ignoreObjectError />
            </VFlow>
          </Cell>
          <Cell size={12}>
            <ClassificacaoPrioridadeField name={path.prioridade} required />
          </Cell>
          <Cell size={12}>
            <RichTextField
              name={path.discussao}
              label={PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED ? 'Discussão' : 'Discussão de caso clínico'}
              placeholder='Insira objetivamente o relato do caso, além de dúvidas e questionamentos'
              maxLength={1000}
              required
            />
          </Cell>
          {((PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && isCuidadoCompartilhado) ||
            !PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED) && (
            <Cell size={12}>
              <FormControl
                label='Após devida explicação, o cidadão declara concordar de forma livre e esclarecida que o agendamento da consulta com o especialista poderá ser mediada por tecnologia?'
                required
              >
                <HFlow>
                  <SimNaoRadioGroupField name={path.cidadaoAceitaAtendTic} />
                </HFlow>
              </FormControl>
            </Cell>
          )}
        </Fragment>
      )}
    </Grid>
  )
}

const styles = {
  hFlow: css`
    grid-auto-columns: 1fr auto;
  `,
  iconTooltip: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
}
