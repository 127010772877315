import { createValidator, required, RuleMap } from 'util/validation'

import { absenteismoKeyMapping } from '../absenteismo/keyMapping-relatorioGerencialAbsenteismo'
import { RelatorioGerencialAbsenteismoModel } from '../absenteismo/model-relatorioGerencialAbsenteismo'
import { atendimentoProfissionalKeyMapping } from '../atendimentos/keyMapping-relatorioGerencialAtendimento'
import { RelatorioGerencialAtendimentoProfissionalModel } from '../atendimentos/model-relatorioGerencialAtendimentoProfissional'
import { cuidadoCompartilhadoKeyMapping } from '../cuidado-compartilhado/keyMapping-relatorioGerencialCuidadoCompartilhado'
import { RelatorioGerencialCuidadoCompartilhadoModel } from '../cuidado-compartilhado/model-relatorioGerencialCuidadoCompartilhado'
import { CamposDisponiveisSelectFieldsModel } from './CamposDisponiveisSelectFields'

const IGNORED_FIELD_KEYS: Array<
  | keyof RelatorioGerencialAtendimentoProfissionalModel
  | keyof RelatorioGerencialAbsenteismoModel
  | keyof RelatorioGerencialCuidadoCompartilhadoModel
> = ['uf', 'dia', 'semana']

const ALL_FIELD_KEYS = [
  ...atendimentoProfissionalKeyMapping.keys(),
  ...absenteismoKeyMapping.keys(),
  ...cuidadoCompartilhadoKeyMapping.keys(),
].filter((key) => !IGNORED_FIELD_KEYS.includes(key))

const rules: RuleMap<CamposDisponiveisSelectFieldsModel> = {}

ALL_FIELD_KEYS.forEach((key) => (rules[key] = [required]))

// TODO (eldorado #19208) manter somente uma validacao
export const camposDisponiveisValidator = createValidator<CamposDisponiveisSelectFieldsModel>(rules)
