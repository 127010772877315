import { LoadingIndicator } from 'components/loading'
import { useHistoricoZikaDetailQuery } from 'graphql/hooks.generated'
import React, { memo } from 'react'
import { HistoricoDwPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import { mountInfosAdministrativas } from 'view/atendimentos/detail/historico/utils/historicoUtils'

import HistoricoGapPanel from '../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../HistoricoNoDetailAvailable'
import { ResultadosDeExamesZikaContent } from './ResultadosDeExamesZikaContent'

interface HistoricoZikaPanelProps extends HistoricoDwPanelProps {}

function HistoricoZikaPanel({ uuidRegistro, cpfOuCnsCidadao, idadeOrigemAtendimento }: HistoricoZikaPanelProps) {
  const {
    data: { historicoZikaDetail: detalhamento },
    loading,
  } = useHistoricoZikaDetailQuery({
    variables: {
      input: {
        uuidRegistro,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!detalhamento) return <HistoricoNoDetailAvailable />

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativas(detalhamento)}
    >
      <ResultadosDeExamesZikaContent detalhamento={detalhamento} />
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoZikaPanel)
