/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Heading, isEmpty, Text, VFlow } from 'bold-ui'
import { isProcedimentoAdministrativo } from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import { PageContainer } from 'components/layout/PageContainer'
import { LoadingIndicator } from 'components/loading'
import { css } from 'emotion'
import { useEscutaInicialDetailQuery } from 'graphql/hooks.generated'
import { ClassificacaoRisco, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { partition } from 'lodash'
import MotivoConsultaBox from 'view/atendimentos/detail/historico/components/accordion/detail/atendimento-individual/consulta/MotivoConsultaBox'
import HistoricoEscutaInicialProcedimentoTable from 'view/atendimentos/detail/historico/components/accordion/detail/escuta-inicial/HistoricoEscutaInicialProcedimentoTable'
import HistoricoGapPanel from 'view/atendimentos/detail/historico/components/accordion/detail/HistoricoGapPanel'
import HistoricoMedicoesTableBox from 'view/atendimentos/detail/historico/components/accordion/detail/HistoricoMedicoesTableBox'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import { recordEscutaHistoricoTagStyle } from 'view/atendimentos/detail/historico/model/model-historico'
import {
  ClassificacaoRiscoColor,
  hasMedicoes,
  mountInfosAdministrativasAtendimentosRecentes,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/historicoUtils'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

interface EscutaInicialVisualizarDetailProps {
  atendimentoProfissionalId: ID
  isPreAtendimento: boolean
}

export function EscutaInicialVisualizarDetail(props: EscutaInicialVisualizarDetailProps) {
  const { atendimentoProfissionalId, isPreAtendimento } = props

  const styles = createStyles()

  const {
    data: { escutaInicialDetail: escutaInicialRealizada },
    loading,
  } = useEscutaInicialDetailQuery({
    variables: {
      input: {
        idAtend: atendimentoProfissionalId,
        isOrigemPec: true,
      },
    },
  })

  if (loading) return <LoadingIndicator message='Carregando...' />

  if (!escutaInicialRealizada) return null

  const isAgudo = escutaInicialRealizada.classificacaoRisco !== ClassificacaoRisco.PEC_NAO_AGUDA
  const procedimentos = escutaInicialRealizada.procedimentosAtendimento?.map((i) => i.procedimento)
  const medicoes = escutaInicialRealizada.medicoes?.map((medicao) => medicao as HistoricoMedicaoModel)
  const isUpa = escutaInicialRealizada.lotacao.unidadeSaude.tipoEstabelecimento === TipoEstabelecimentoEnum.UPA
  const [procedimentosAdm, procedimentosClinicos] = partition(procedimentos, (procedimento) =>
    isProcedimentoAdministrativo(procedimento.tipoProcedimento)
  )

  return (
    <PageContainer fluid>
      <VFlow vSpacing={1} style={styles.border}>
        <HistoricoGapPanel
          informacoesAdministrativas={mountInfosAdministrativasAtendimentosRecentes(escutaInicialRealizada)}
        >
          <MotivoConsultaBox subjetivoCiaps={escutaInicialRealizada.evolucaoSubjetivo?.ciaps} level={3} />
          <Text dangerouslySetInnerHTML={{ __html: escutaInicialRealizada.evolucaoSubjetivo?.descricao }} />
          {hasMedicoes(medicoes) && <HistoricoMedicoesTableBox sectionStyle='I' medicoes={medicoes} />}
          {!isEmpty(escutaInicialRealizada.procedimentosAtendimento) && (
            <VFlow vSpacing={0.5}>
              <Heading level={5}>Intervenções e/ou procedimentos clínicos</Heading>
              <HistoricoEscutaInicialProcedimentoTable procedimentos={procedimentosClinicos} />
            </VFlow>
          )}
          {!isPreAtendimento &&
            escutaInicialRealizada.classificacaoRisco &&
            escutaInicialRealizada.classificacaoRisco !== ClassificacaoRisco.NAO_CLASSIFICADO && (
              <VFlow vSpacing={0.5}>
                <Heading level={3}>Classificação de risco/Vulnerabilidade</Heading>
                <StatusSquare
                  fontWeight='bold'
                  color={ClassificacaoRiscoColor[escutaInicialRealizada.classificacaoRisco]}
                  description={`${isAgudo ? 'Aguda - ' : ''}${
                    recordEscutaHistoricoTagStyle[escutaInicialRealizada.classificacaoRisco].descricao
                  }`}
                />
              </VFlow>
            )}
          {isUpa && (
            <VFlow vSpacing={0.5}>
              <Heading level={3}>Dados de desfecho</Heading>
              {!isEmpty(procedimentosAdm) ? (
                <VFlow vSpacing={0.5}>
                  <Heading level={5}>Procedimentos Administrativos (SIGTAP)</Heading>
                  <HistoricoEscutaInicialProcedimentoTable procedimentos={procedimentosAdm} />
                </VFlow>
              ) : (
                <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
              )}
            </VFlow>
          )}
        </HistoricoGapPanel>
      </VFlow>
    </PageContainer>
  )
}

const createStyles = () => ({
  border: css`
    margin: 1rem 0;
  `,
})
