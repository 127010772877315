/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  FormControl,
  Grid,
  Heading,
  HFlow,
  ModalBody,
  ModalFooter,
  Theme,
  useTheme,
  VFlow,
} from 'bold-ui'
import { ErrorField, RadioField, TextField } from 'components/form'
import { CiapCidSelectField } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectField } from 'components/form/field/select/GrupoCondicaoSelectField'
import { confirm } from 'components/modals/confirm'
import { KEY_NOT_DEPENDENT_AGGREGATORS } from 'components/pivot-table/components/aggregator/util-aggregator'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-calculate'
import {
  ModelosByTipoDocument,
  useEditarModeloPersonalizadoRelatorioGerencialMutation,
  useExcluirModeloPersonalizadoRelatorioGerencialMutation,
  useSalvarModeloPersonalizadoRelatorioGerencialMutation,
} from 'graphql/hooks.generated'
import {
  AggregatorRelatorioGerencialEnum,
  ModeloPersonalizadoRelatorioGerencial,
  OpcaoSelecionadaRelatorioGerencialEnum,
  TipoModuloEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { KeyMapping } from '../../../common/keyMapping-relatorioGerencial'
import { RelatorioGerencialPeriodoLabel } from '../../form/RelatorioGerencialPeriodoLabel'
import { RelatorioGerencialPeriodoRadioField } from '../../form/RelatorioGerencialPeriodoRadioField'
import { RelatorioGerencialUnidadePeriodoSelectField } from '../../form/RelatorioGerencialUnidadePeriodoSelectField'
import { createModeloPersonalizadoRelatoriosGerenciaisPeriodoFormCalculator } from './calculator-modeloPersonalizadoRelatorioGerencial'
import {
  ModeloPersonalizadoRelatorioGerencialFormModel,
  relatorioGerencialFormModelToJsonModel,
} from './model-modeloPersonalizadoRelatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialCamposForm } from './ModeloPersonalizadoRelatorioGerencialCamposForm'
import { modeloPersonalizadoRelatorioGerencialFormValidator } from './validator-modeloPersonalizadoRelatorioGerencial'

const meta = metaPath<ModeloPersonalizadoRelatorioGerencialFormModel>()

interface ModeloPersonalizadoRelatorioGerencialFormProps {
  keyMapping: Map<string, KeyMapping>
  editValues: ModeloPersonalizadoRelatorioGerencial
  tipoModulo: TipoModuloEnum
  tituloModal: string
  handleCloseModal: () => void
  isCadastro: boolean
}

export const ModeloPersonalizadoRelatorioGerencialForm = (props: ModeloPersonalizadoRelatorioGerencialFormProps) => {
  const { keyMapping, editValues, tipoModulo, tituloModal, handleCloseModal, isCadastro } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  const initialValues = useMemo(
    () => ({
      id: editValues?.id,
      nome: editValues?.nome,
      modulo: editValues?.tipoModulo ?? tipoModulo,
      opcaoSelecionada:
        editValues?.modeloPersonalizado.opcaoSelecionada ?? OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA,
      unidadePeriodo: editValues?.modeloPersonalizado.unidadePeriodo ?? UnidadePeriodoRelatorioGerencialEnum.DIA,
      gruposCondicoes: [],
      ciapsCids: [],
      campos:
        editValues?.modeloPersonalizado.campos.map((campo, index) => ({
          _id: index,
          key: campo.key,
          posicao: campo.posicao,
          values: campo.values,
        })) ?? [],
      aggregator: editValues?.modeloPersonalizado.aggregator ?? AggregatorRelatorioGerencialEnum.CONTAGEM,
      isPublico: editValues?.isPublico ?? false,
    }),
    [editValues, tipoModulo]
  )

  const [salvarModeloPersonalizadoRelatorioGerencial] = useSalvarModeloPersonalizadoRelatorioGerencialMutation()
  const [editarModeloPersonalizadoRelatorioGerencial] = useEditarModeloPersonalizadoRelatorioGerencialMutation()
  const [excluirModeloPersonalizadoRelatorioGerencial] = useExcluirModeloPersonalizadoRelatorioGerencialMutation()

  const onSubmit = (values: ModeloPersonalizadoRelatorioGerencialFormModel) => {
    if (isCadastro) {
      salvarModeloPersonalizadoRelatorioGerencial({
        variables: {
          input: {
            isPublico: values.isPublico,
            modeloPersonalizado: relatorioGerencialFormModelToJsonModel(values),
            nome: values.nome,
            tipoModulo: values.modulo,
          },
        },
        refetchQueries: [{ query: ModelosByTipoDocument, variables: { tipoModulo } }],
      })
    } else {
      editarModeloPersonalizadoRelatorioGerencial({
        variables: {
          input: {
            id: values.id,
            modeloPersonalizado: relatorioGerencialFormModelToJsonModel(values),
            nome: values.nome,
            tipoModulo: values.modulo,
          },
        },
        refetchQueries: [{ query: ModelosByTipoDocument, variables: { tipoModulo } }],
      })
    }
    handleCloseModal()
  }

  const handleExcluir = () => {
    return confirm({
      title: `Deseja excluir o modelo de relatório?`,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: async () => {
        excluirModeloPersonalizadoRelatorioGerencial({
          variables: {
            input: {
              id: editValues?.id,
              tipoModulo,
            },
          },
          refetchQueries: [{ query: ModelosByTipoDocument, variables: { tipoModulo } }],
        })
        handleCloseModal()
      },
    })()
  }

  const decorators = useMemo(
    () => [createDecorator(createModeloPersonalizadoRelatoriosGerenciaisPeriodoFormCalculator(meta))],
    []
  )

  const hasFiltroCiapCid = tipoModulo === TipoModuloEnum.RELATORIO_GERENCIAL_ATENDIMENTO
  const renderForm = ({
    values,
    handleSubmit,
    errors,
    submitFailed,
  }: FormRenderProps<ModeloPersonalizadoRelatorioGerencialFormModel>) => {
    return (
      <Fragment>
        <ModalBody>
          <VFlow>
            <Heading level={1}>{tituloModal}</Heading>
            <Grid wrap>
              <Cell size={6}>
                <TextField name={meta.nome} label='Nome do modelo' required size={6} />
              </Cell>
              <Cell size={12}>
                <VFlow>
                  <RelatorioGerencialPeriodoLabel />
                  <ErrorField name={FORM_ERROR} />
                  <VFlow css={styles.periodoContainer}>
                    <Grid wrap alignItems='center'>
                      <Cell size={6}>
                        <RelatorioGerencialUnidadePeriodoSelectField
                          name={meta.unidadePeriodo}
                          style={styles.unidadePeriodoSelectField}
                        />
                      </Cell>
                      <Cell>
                        <RelatorioGerencialPeriodoRadioField
                          nameOpcaoSelecionada={meta.opcaoSelecionada}
                          namePeriodo={meta.periodo}
                          opcaoSelecionadaValue={values.opcaoSelecionada}
                          unidadeTempoValue={values.unidadePeriodo}
                          isModal
                        />
                      </Cell>
                    </Grid>
                  </VFlow>
                  {hasFiltroCiapCid && (
                    <Grid alignItems='stretch' direction='row'>
                      <Cell size={6}>
                        <CiapCidSelectField
                          label='CIAP2 e CID10'
                          placeholder='Selecione outros CIAP2 e CID10'
                          name={meta.ciapsCids}
                          multiple
                        />
                      </Cell>
                      <Cell size={6}>
                        <GrupoCondicaoSelectField
                          label='Grupos de condições prioritários'
                          placeholder='Selecione grupos de condições prioritários'
                          name={meta.gruposCondicoes}
                          multiple
                        />
                      </Cell>
                    </Grid>
                  )}
                  <div style={styles.divider}></div>
                  <ModeloPersonalizadoRelatorioGerencialCamposForm
                    keyMapping={keyMapping}
                    meta={meta.campos}
                    campos={values?.campos}
                    isPublico={values?.isPublico}
                    errors={submitFailed && errors}
                  />
                  <FormControl label='Forma de apresentação' required error={errors.formaDeApresentacao}>
                    <HFlow>
                      {KEY_NOT_DEPENDENT_AGGREGATORS.map((f) => (
                        <RadioField name={meta.aggregator} label={f.label} value={f.id} key={'modal_' + f.label} />
                      ))}
                    </HFlow>
                  </FormControl>
                </VFlow>
              </Cell>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='space-between'>
            {!isCadastro ? (
              <Button kind='danger' hidden={isCadastro} onClick={handleExcluir}>
                Excluir modelo
              </Button>
            ) : (
              <div></div>
            )}
            <HFlow justifyContent='flex-end'>
              <Button kind='normal' onClick={handleCloseModal}>
                Cancelar
              </Button>
              <Button kind='primary' onClick={handleSubmit}>
                Salvar
              </Button>
            </HFlow>
          </HFlow>
        </ModalFooter>
      </Fragment>
    )
  }
  return (
    <Form<ModeloPersonalizadoRelatorioGerencialFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={decorators}
      validate={modeloPersonalizadoRelatorioGerencialFormValidator(hasFiltroCiapCid)}
    />
  )
}

const createStyles = (theme: Theme) => ({
  periodoContainer: css`
    position: relative;
    padding: 1rem;
    border: 1px solid ${theme.pallete.gray.c80};
  `,

  unidadePeriodoSelectField: css`
    position: absolute;
    margin-left: -0.5rem;
    top: -0.7rem;
    padding: 0 0.5rem;
    background-color: ${theme.pallete.surface.main};
  `,

  divider: {
    borderBottom: `1px solid ${theme.pallete.divider}`,
  },
})
