import { Alert, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PageContent } from 'components/layout/PageContent'
import { Action, CuidadoCompartilhadoQueryFilterEnum, CuidadosCompartilhadoQueryInput } from 'graphql/types.generated'
import React from 'react'

import {
  CuidadoCompartilhadoItemActions,
  CuidadoCompartilhadoSort,
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
} from '../model-cuidadoCompartilhado'
import { ListaCuidadoCompartilhadoHeader } from './ListaCuidadoCompartilhadoHeader'
import { ListaCuidadoCompartilhadoView } from './ListaCuidadoCompartilhadoView'

const filterToQueryInput = (
  acessoId: ID,
  value: ListaCuidadoCompartilhadoFilterModel
): CuidadosCompartilhadoQueryInput => {
  const hasExecutante = value.profissionalExecutante?.profissional?.id
  return {
    query: value.query,
    status: value.status,
    dataInicio: value.periodo?.startDate,
    dataFim: value.periodo?.endDate,
    executadosPelaMinhaEquipe: hasExecutante ? false : !value.somenteOsMeus,
    executadosPorProfissionalId: hasExecutante ?? (value.somenteOsMeus ? acessoId : null),
    solicitadosPorProfissionalId: value.profissionalSolicitante?.profissional?.id,
    unidadeSaudeId: value.unidadeSaude?.id,
    pageParams: { ...value.pageParams, sort: CuidadoCompartilhadoSort[value.pageParams.sort] },
  }
}

const defaultSort = [
  CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE,
  CuidadoCompartilhadoSortEnum.STATUS_EXECUTANTE,
]

const resolveDiscutirAction = (actions: CuidadoCompartilhadoItemActions): Action => actions?.discutirCasoExecutante

export const ListaCuidadoCompartilhadoExecutanteView = () => {
  const { profissional } = useAcessoLotacaoOrEstagio()

  const filterToQuery = (value: ListaCuidadoCompartilhadoFilterModel) => filterToQueryInput(profissional?.id, value)

  return (
    <>
      <ListaCuidadoCompartilhadoHeader />
      <PageContent fluid type='transparent'>
        <VFlow vSpacing={0.5}>
          <Alert type='info' inline>
            A lista apresenta todos os cuidados compartilhados com você e com a sua equipe.
          </Alert>
          <ListaCuidadoCompartilhadoView
            defaultSort={CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE}
            itemsToSort={defaultSort}
            filterSomenteOsMeusLabel='Ver somente cuidados compartilhados comigo'
            role={CuidadoCompartilhadoQueryFilterEnum.EXECUTANTE}
            filterToQueryInput={filterToQuery}
            resolveDiscutirAction={resolveDiscutirAction}
          />
        </VFlow>
      </PageContent>
    </>
  )
}
