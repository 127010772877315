import { QueryHookOptions, useApolloClient } from '@apollo/react-hooks'
import atmosphere from 'atmosphere.js'
import { useSessaoQuery } from 'graphql/hooks.generated'
import { AcessoCbo, Cbo, SessaoQuery, SessaoQueryVariables, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useCookieConfig } from 'hooks/useCookieConfig'
import { useCallback, useMemo } from 'react'
import GruposCbo, { GrupoCbo } from 'types/GruposCbo'
import { grupoCboIncludesCbo } from 'types/NivelCbo'
import { Permission } from 'types/Permissions'
import { notify } from 'util/multitab'

import { Authorization } from './model-authorization'
import { useLoginAnalytics } from './useLoginAnalytics'
import { isCboPermission, isLotacaoOrEstagio, isPermission, isPermissionArray } from './useSessionUtils'

export type UseSessionOptions = QueryHookOptions<SessaoQuery, SessaoQueryVariables>

export default (options: UseSessionOptions = {}) => {
  const client = useApolloClient()
  const { denyCookies } = useCookieConfig()

  const { data, refetch, loading } = useSessaoQuery({
    fetchPolicy: 'cache-first',
    ...options,
  })

  const resetApolloCache = useCallback(async () => {
    await client.cache.reset()
  }, [client])

  const { remove: removeLoginAnalytics } = useLoginAnalytics()

  const afterLogout = useCallback(async () => {
    await resetApolloCache()
    atmosphere.unsubscribe()
    window.localStorage.removeItem('LOGIN')
    window.localStorage.removeItem('LOGOUT')
    window.localStorage.removeItem('PERFIL_SELECIONADO')
    window.localStorage.removeItem('RESET_SESSION')
    window.localStorage.removeItem('NEWS')

    removeLoginAnalytics()
    denyCookies()
  }, [resetApolloCache, removeLoginAnalytics, denyCookies])

  const logoutSuccess = useCallback(async () => {
    notify('LOGOUT')
    afterLogout()
  }, [afterLogout])

  const recursosSet = useMemo(() => new Set(data?.sessao?.recursos), [data])
  const hasAuthorization = useCallback((permission: Permission) => recursosSet.has(permission.uri), [recursosSet])

  const acesso = data.sessao?.acesso
  const hasCboAuth = useCallback(
    (acessos: AcessoCbo[]) => {
      if (hasCbo(acesso)) {
        const sessionCbo: Cbo = acesso?.cbo
        const groups: GrupoCbo[] = GruposCbo.filter((grupoCbo: GrupoCbo) =>
          acessos.find((acesso) => acesso === grupoCbo.grupo)
        ).map((item) => item as GrupoCbo)

        return groups.some((item: GrupoCbo) => grupoCboIncludesCbo(item.cbos, sessionCbo.cbo2002))
      } else {
        return false
      }
    },
    [acesso]
  )

  const tipoEstabelecimento = acesso && isLotacaoOrEstagio(acesso) && acesso.unidadeSaude.tipoEstabelecimento

  const isEstabelecimentoAtencaoPrimaria =
    acesso && isLotacaoOrEstagio(acesso) && acesso.unidadeSaude.estabelecimentoAtencaoPrimaria

  const hasTipoEstabelecimentoAuth = useCallback(
    (tiposEstabelecimento: TipoEstabelecimentoEnum[]) => {
      return tiposEstabelecimento.includes(tipoEstabelecimento)
    },
    [tipoEstabelecimento]
  )

  const hasPermissionAuth = useCallback((permissions: Permission[]) => permissions.some(hasAuthorization), [
    hasAuthorization,
  ])

  const checkAuthorization = useCallback(
    (permission: Authorization) => {
      if (isPermission(permission)) {
        return hasAuthorization(permission)
      } else if (isCboPermission(permission)) {
        return hasCboAuth(permission)
      } else if (isPermissionArray(permission)) {
        return hasPermissionAuth(permission)
      }
    },
    [hasAuthorization, hasCboAuth, hasPermissionAuth]
  )

  return {
    loading,
    data: data && data.sessao,
    isEstagio: acesso?.__typename === 'Estagio',
    tipoEstabelecimento,
    isEstabelecimentoAtencaoPrimaria,
    resetApolloCache,
    logoutSuccess,
    afterLogout,
    refresh: () => refetch(),
    hasAuthorization,
    hasCboAuth,
    hasTipoEstabelecimentoAuth,
    checkAuthorization,
  }
}

const hasCbo = (acesso): acesso is { cbo: Cbo } => !!acesso?.cbo
