import { SelectField } from 'components/form/final-form'
import { DocumentNode } from 'graphql'
import React from 'react'

import { AsyncSelectFieldProps, useAsyncQuerySelect, UseAsyncQuerySelectProps } from '../useAsyncQuerySelect'

interface CampoRelatorioGerencialSelectFieldProps<TData, TVariables>
  extends AsyncSelectFieldProps<string>,
    Pick<
      UseAsyncQuerySelectProps<string, TData, TVariables>,
      'variables' | 'extractItems' | 'itemToString' | 'refetchOnFilterChange'
    > {
  queryDocument: DocumentNode
  label?: string
  refetchOnFilterChange?: boolean
}

const itemToString = (item: string) => item

export const TODOS_OS_ITEMS = 'Todos os itens'

export function CampoRelatorioGerencialSelectField<TData, TVariables>(
  props: CampoRelatorioGerencialSelectFieldProps<TData, TVariables>
) {
  const { queryDocument, extractItems, variables, name, refetchOnFilterChange = false, ...rest } = props
  const { selectProps } = useAsyncQuerySelect<string, TData, TVariables>({
    query: queryDocument,
    extractItems,
    variables,
    itemToString,
    refetchOnFilterChange,
  })

  const { items, ...selectRest } = selectProps
  const fullItems = refetchOnFilterChange ? items : items && [TODOS_OS_ITEMS, ...items]

  return (
    <>
      <SelectField<string>
        name={name}
        items={fullItems}
        itemToString={itemToString}
        multiple
        // TODO (eldorado #19208) definir initialValue no form da modal
        initialValue={TODOS_OS_ITEMS}
        {...rest}
        {...selectRest}
      />
    </>
  )
}
