/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { SideMenuSublink } from 'components/side-menu-accordion/SideMenuSublink'
import { useCalculaCabecalhoSideMenu } from 'components/side-menu-accordion/useCalculaCabecalhoSideMenu'

import { TermosDeUsoSideMenuAccordionItem } from './TermosDeUsoSideMenuAccordionItem'
import { SIDE_MENU_ITEMS } from './TermosDeUsoSideMenuItens'

const ASIDE_HEIGHT_DIFF = 6.1 / 16 + 1.5
const ALTURA_CABECALHO = 310
const ALTURA_CABECALHO_TO_MENU = 340

export const TermosDeUsoSideMenu = () => {
  const passouCabecalho = useCalculaCabecalhoSideMenu(ALTURA_CABECALHO, ALTURA_CABECALHO_TO_MENU)
  const theme = useTheme()
  const styles = createStyles(theme, passouCabecalho)

  return (
    <aside css={styles.aside}>
      {SIDE_MENU_ITEMS.map((item, index) => {
        return (
          <TermosDeUsoSideMenuAccordionItem
            title={item.title}
            index={index}
            totalItems={SIDE_MENU_ITEMS.length}
            key={item.title}
          >
            {item.children.map((subItem) => {
              return <SideMenuSublink to={subItem.to} key={subItem.title} title={subItem.title} />
            })}
          </TermosDeUsoSideMenuAccordionItem>
        )
      })}
    </aside>
  )
}

const createStyles = (theme: Theme, passouCabecalho: boolean) => ({
  aside: css`
    width: 100%;
    max-width: 272px;
    padding-right: 1.5rem;
    border-right: 1px solid ${theme.pallete.divider};
    position: ${passouCabecalho ? 'fixed' : 'static'};
    top: 1rem;
    padding-left: 0.5rem;
    height: calc(100% - ${ASIDE_HEIGHT_DIFF}rem);
    overflow: hidden auto;

    ${theme.breakpoints.up('xl')} {
      //1920
      width: ${passouCabecalho ? 'calc(100% / 15 * 2)' : 'auto'};
    }

    @media only screen and (min-width: 1650px) and (max-width: 1920px) {
      width: ${passouCabecalho ? 'calc(100% / 15 * 2.2)' : 'auto'};
    }

    @media only screen and (min-width: 1450px) and (max-width: 1649px) {
      width: ${passouCabecalho ? 'calc(100% / 15 * 2.6)' : 'auto'};
    }

    @media only screen and (min-width: 1281px) and (max-width: 1449px) {
      width: ${passouCabecalho ? 'calc(100% / 15 * 2.9)' : 'auto'};
    }

    ${theme.breakpoints.down('lg')} {
      //1280
      width: ${passouCabecalho ? 'calc(100% / 15 * 3.2)' : 'auto'};
    }

    ${theme.breakpoints.up('lg')} {
      padding-left: 1rem;
    }
    &::-webkit-scrollbar {
      width: 0.3rem;
      height: 0.3rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.pallete.gray.c40};
      border-radius: ${theme.radius.tag};
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${theme.pallete.gray.c60};
    }

    &::-webkit-scrollbar-track {
      background: ${theme.pallete.surface.main};
      border-radius: ${theme.radius.tag};
      box-shadow: inset 0.4rem 0.625rem 0.75rem ${theme.pallete.divider};
    }
  `,
})
