import { Button, Cell, FormControl, Grid, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { RadioField, SelectField } from 'components/form'
import { ValidationErrors } from 'final-form'
import { PosicaoCampoRelatorioGerencialEnum } from 'graphql/types.generated'
import React, { Fragment, useMemo } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { MetaArray } from 'util/metaPath'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { EditableListForm, EditableListFormRenderProps } from 'view/atendimentos/detail/components/EditableListForm'

import { KeyMapping, VisibilidadeModeloPersonalizadoEnum } from '../../../common/keyMapping-relatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialCampoModel } from './model-modeloPersonalizadoRelatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialCampoDataTable } from './ModeloPersonalizadoRelatorioGerencialCampoDataTable'
import { modeloPersonalizadoRelatorioGerencialCamposFormValidator } from './validator-modeloPersonalizadoRelatorioGerencial'

interface ModeloPersonalizadoRelatorioGerencialCamposFormProps {
  meta: MetaArray<ModeloPersonalizadoRelatorioGerencialCampoModel>
  keyMapping: Map<string, KeyMapping>
  campos?: ModeloPersonalizadoRelatorioGerencialCampoModel[]
  isPublico?: boolean
  errors?: ValidationErrors
}

export const ModeloPersonalizadoRelatorioGerencialCamposForm = (
  props: ModeloPersonalizadoRelatorioGerencialCamposFormProps
) => {
  const { meta, keyMapping, campos, isPublico, errors } = props
  const theme = useTheme()
  const styles = createStyles(theme)
  const initialValues = useMemo(
    () => ({
      _id: 'new',
      key: null,
      posicao: PosicaoCampoRelatorioGerencialEnum.FILTRO,
      values: [],
    }),
    []
  )

  const selecaoCamposLabel = (
    <Fragment>
      Seleção de campos
      <Tooltip text='Adicione pelo menos uma linha/coluna'>
        <Icon icon='infoCircleFilled' size={1} />
      </Tooltip>
    </Fragment>
  )

  const camposDisponiveis: string[] = useMemo(
    () =>
      keyMapping
        ? [...keyMapping]
            .filter(
              ([key, value]) =>
                (value.visibility === VisibilidadeModeloPersonalizadoEnum.PUBLICO ||
                  (!isPublico && value.visibility === VisibilidadeModeloPersonalizadoEnum.PRIVADO)) &&
                !campos?.some((campo) => campo.key === key)
            )
            .map(([key, _]) => key)
            .sort()
        : [],
    [campos, isPublico, keyMapping]
  )
  const { handleSubmit, removeItem } = useEditableListField({ name: meta, subscription: { value: true } })

  const renderForm = (props: EditableListFormRenderProps<ModeloPersonalizadoRelatorioGerencialCampoModel>) => {
    const { name, values, handleSubmit } = props
    return (
      <VFlow>
        <FormControl label={selecaoCamposLabel} required error={errors?.campos}>
          <div style={styles.selecaoCampos}>
            <Grid justifyContent='space-between'>
              <Cell size={6}>
                <VFlow vSpacing={0.3}>
                  <Text variant='h5'>Campos disponíveis</Text>
                  <SelectField
                    name={name.key}
                    items={camposDisponiveis}
                    itemToString={(item) => keyMapping.get(item)?.keyName}
                    placeholder='Selecione um campo'
                    value={values.key}
                  />
                </VFlow>
              </Cell>
              <Cell size={6}>
                <VFlow vSpacing={0.3}>
                  <Text variant='h5'>Itens</Text>
                  <SelectField
                    name={name.values}
                    items={[]}
                    itemToString={(item) => 'texto'}
                    disabled={isUndefinedOrNull(values.key)}
                    multiple
                  />
                </VFlow>
              </Cell>
              <Cell size={6}>
                <VFlow>
                  <Text variant='h5'>Modo de uso</Text>
                  <HFlow style={{ alignContent: 'space-between' }}>
                    <HFlow>
                      <RadioField
                        name={name.posicao}
                        label={
                          <HFlow hSpacing={0.3} alignItems='center'>
                            <Icon icon='filterOutline' />
                            <Text>Filtro</Text>
                          </HFlow>
                        }
                        value={PosicaoCampoRelatorioGerencialEnum.FILTRO}
                      />
                      <RadioField
                        name={name.posicao}
                        label={
                          <HFlow hSpacing={0.3} alignItems='center'>
                            <Icon icon='hamburguerMenu' style={styles.rotated} />
                            <Text>Coluna</Text>
                          </HFlow>
                        }
                        value={PosicaoCampoRelatorioGerencialEnum.COLUNA}
                      />
                      <RadioField
                        name={name.posicao}
                        label={
                          <HFlow hSpacing={0.3} alignItems='center'>
                            <Icon icon='hamburguerMenu' />
                            <Text>Linha</Text>
                          </HFlow>
                        }
                        value={PosicaoCampoRelatorioGerencialEnum.LINHA}
                      />
                    </HFlow>
                  </HFlow>
                </VFlow>
              </Cell>
              <Cell flexShrink={1} alignSelf='flex-end'>
                <Button onClick={handleSubmit} kind='primary' size='small'>
                  Adicionar
                </Button>
              </Cell>
            </Grid>
          </div>
        </FormControl>
        <ModeloPersonalizadoRelatorioGerencialCampoDataTable
          keyMapping={keyMapping}
          campos={campos}
          removeItem={removeItem}
        />
      </VFlow>
    )
  }
  return (
    <EditableListForm<ModeloPersonalizadoRelatorioGerencialCampoModel>
      render={renderForm}
      prefix='campo'
      onSubmit={handleSubmit}
      validate={modeloPersonalizadoRelatorioGerencialCamposFormValidator(campos)}
      initialValues={initialValues}
    />
  )
}

const createStyles = (theme: Theme) => ({
  selecaoCampos: {
    padding: `0.7rem 1rem 1rem 1rem`,
    border: `1px solid ${theme.pallete.gray.c80}`,
  },
  rotated: {
    transform: 'rotate(90deg)',
  },
})
