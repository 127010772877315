/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HeadingSection, HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { blue, ColorScale, pink } from 'bold-ui/lib/styles/colors'
import { useAlert } from 'components/alert'
import { CboSelectField, FormProps, SubmitButton } from 'components/form'
import { useFlags } from 'config/useFlagsContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ReactComponent as CuidadoCompartilhado } from 'images/historico/icone-cuidado-compartilhado.svg'
import { ReactComponent as GuiaEncaminhamento } from 'images/soap/ic_guia-encaminhamento.svg'
import { useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { CuidadoCompartilhadoPlanoModel, TipoCompartilhamentoCuidadoModel } from '../model-cuidadocompartilhado'
import { CuidadoCompartilhadoForm } from './CuidadoCompartilhadoForm'
import { CuidadoCompartilhadoFormFields } from './CuidadoCompartilhadoFormFields'

interface AdicionarCuidadoCompartilhadoFormProps {
  onSubmit: FormProps<CuidadoCompartilhadoPlanoModel>['onSubmit']
}

const path = metaPath<CuidadoCompartilhadoPlanoModel>()

export const AdicionarCuidadoCompartilhadoForm = (props: AdicionarCuidadoCompartilhadoFormProps) => {
  const { onSubmit } = props

  const alert = useAlert()
  const { analytics } = useFirebase()
  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()
  const theme = useTheme()
  const localStyles = createStyles(theme)

  const [disabled, setDisabled] = useState<boolean>(PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED)
  const [registradoVia, setRegistradoVia] = useState<TipoCompartilhamentoCuidadoModel>(null)

  const onSubmitSucceeded = () => {
    alert('success', 'Cuidado compartilhado salvo com sucesso.')
    analytics.logEvent('CC_adicionado')
  }

  const renderForm = (renderProps: FormRenderProps<CuidadoCompartilhadoPlanoModel>) => {
    const onCancel = () => {
      renderProps.form.reset()
      renderProps.form.getRegisteredFields().forEach((item) => renderProps.form.resetFieldState(item))
    }

    return PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED ? (
      <VFlow vSpacing={0.5}>
        <HeadingSection level={4} css={localStyles.heading} title='Compartilhamento de cuidado' />
        <div css={localStyles.wrapper}>
          <div css={localStyles.header}>
            <Grid>
              <Cell size={6}>
                <CboSelectField
                  name={path.cbo}
                  label='Compartilhar com a CBO'
                  mostrarIndisponivelLotacao={false}
                  nivelSuperior
                  required
                />
              </Cell>
            </Grid>
          </div>
          <div css={localStyles.children}>
            <VFlow>
              <HFlow hSpacing={0.5} alignItems='center'>
                <Heading level={5}>Registrar via:</Heading>
                {registradoVia === 'CUIDADO_COMPARTILHADO' ? (
                  <HFlow hSpacing={0.5} alignItems='center'>
                    <CuidadoCompartilhado css={localStyles.icon(pink)} />
                    <Heading level={4} css={localStyles.registrarHeading(pink)}>
                      Cuidado compartilhado
                    </Heading>
                  </HFlow>
                ) : registradoVia === 'GUIA_ENCAMINHAMENTO' ? (
                  <HFlow hSpacing={0.5} alignItems='center'>
                    <GuiaEncaminhamento css={localStyles.icon(blue)} />
                    <Heading level={4} css={localStyles.registrarHeading(blue)}>
                      Guia de encaminhamento
                    </Heading>
                  </HFlow>
                ) : (
                  <Text>—</Text>
                )}
              </HFlow>
              <CuidadoCompartilhadoFormFields
                path={path}
                disabled={disabled}
                setDisabled={setDisabled}
                setRegistradoVia={setRegistradoVia}
              />
              <HFlow justifyContent='flex-end'>
                <Button size='small' onClick={onCancel}>
                  Cancelar
                </Button>
                <SubmitButton kind='primary' size='small' handleSubmit={renderProps.handleSubmit} disabled={disabled}>
                  Adicionar
                </SubmitButton>
              </HFlow>
            </VFlow>
          </div>
        </div>
      </VFlow>
    ) : (
      <Grid>
        <Cell size={12}>
          <CuidadoCompartilhadoFormFields path={path} />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='small' onClick={onCancel}>
              Cancelar
            </Button>
            <SubmitButton kind='primary' size='small' handleSubmit={renderProps.handleSubmit}>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return (
    <CuidadoCompartilhadoForm
      path={path}
      render={renderForm}
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
    />
  )
}

const createStyles = (theme: Theme) => ({
  header: css`
    background-color: ${theme.pallete.gray.c90};
    border-bottom: 1px solid ${theme.pallete.divider};
    padding: 1rem;
  `,
  children: css`
    padding: 1rem;
  `,
  wrapper: css`
    border: 1px solid ${theme.pallete.divider};
  `,
  heading: css`
    margin-bottom: 0;
  `,
  icon: (color: ColorScale) => css`
    height: 1.5rem;
    width: 1.5rem;
    filter: drop-shadow(0 0.08rem 0.1rem ${color.c60});
  `,
  registrarHeading: (color: ColorScale) => css`
    color: ${color.c40};
  `,
})
