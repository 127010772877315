import { DateRange } from 'bold-ui'
import { CiapCidSelectModel } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField'
import {
  AggregatorRelatorioGerencialEnum,
  CampoSalvoRelatorioGerencialInput,
  ModeloPersonalizadoRelatorioGerencialInput,
  OpcaoSelecionadaRelatorioGerencialEnum,
  TipoModuloEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'

export interface ModeloPersonalizadoRelatorioGerencialFormModel {
  id?: string
  nome: string
  modulo: TipoModuloEnum
  unidadePeriodo: UnidadePeriodoRelatorioGerencialEnum
  opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum
  periodo?: DateRange
  ciapsCids: CiapCidSelectModel[]
  gruposCondicoes: GrupoCondicaoSelectModel[]
  campos: ModeloPersonalizadoRelatorioGerencialCampoModel[]
  aggregator: AggregatorRelatorioGerencialEnum
  isPublico: boolean
}

export interface ModeloPersonalizadoRelatorioGerencialCampoModel extends CampoSalvoRelatorioGerencialInput {
  _id: ID
}

export const relatorioGerencialFormModelToJsonModel = (
  values: ModeloPersonalizadoRelatorioGerencialFormModel
): ModeloPersonalizadoRelatorioGerencialInput => {
  return {
    aggregator: values.aggregator,
    ciapsCids: values.ciapsCids.map((ciapcid) => ciapcid.codigo),
    campos: values.campos.map((campo) => ({
      key: campo.key,
      posicao: campo.posicao,
      values: campo.values,
    })),
    gruposCondicoes: values.gruposCondicoes.map((grupoCondicao) => grupoCondicao.id),
    opcaoSelecionada: values.opcaoSelecionada,
    unidadePeriodo: values.unidadePeriodo,
    periodo: values.periodo,
  }
}
