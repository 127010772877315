/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, hexToRGB, HFlow, Icon, Theme, useTheme } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { SideMenuList } from 'components/layout/SideMenu/SideMenuList'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ReactNode } from 'react'
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'

interface NewsMenuAccordionItemProps {
  title: string
  index: number
  totalItems: number
  children: ReactNode
}

export function TermosDeUsoSideMenuAccordionItem(props: NewsMenuAccordionItemProps) {
  const { title, index, totalItems, children } = props

  const theme = useTheme()

  const isFirst = index === 0
  const isLast = index === totalItems - 1

  const styles = createStyles(theme, isFirst, isLast)

  const { analytics } = useFirebase()

  const handleUserClickOnAccordion = () => analytics.logEvent('user_click_on_side_menu_accordion_termos_de_uso')

  return (
    <Accordion allowZeroExpanded>
      <AccordionItem css={styles.accordionItem} onClick={handleUserClickOnAccordion}>
        <AccordionButton style={styles.accordionButton} wrapperStyle={styles.accordionButtonWrapper}>
          <HFlow alignItems='center' justifyContent='space-between'>
            <HFlow hSpacing={0.5} alignItems='center'>
              <Icon icon='angleDown' />
              <Heading level={5} color='inherit'>
                {title}
              </Heading>
            </HFlow>
          </HFlow>
        </AccordionButton>
        <AccordionItemPanel css={styles.panel}>
          <SideMenuList>{children}</SideMenuList>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

const createStyles = (theme: Theme, isFirst: boolean, isLast: boolean) => ({
  panel: css`
    border: 0.5px ${theme.pallete.divider} solid;
  `,
  accordionButtonWrapper: css`
    padding-bottom: 0;
  `,
  accordionButton: css`
    padding: 0.5rem 0.7rem;
    overflow: hidden;
    border: 1px ${theme.pallete.divider} solid;
    border-radius: ${isLast ? `0 0 ${theme.radius.input}px ${theme.radius.input}px` : '0'};
    background-color: ${theme.pallete.gray.c100};
    margin: 0;
    margin-top: ${isFirst ? '0' : '-1px'};

    &:hover {
      border: 1px ${theme.pallete.divider} solid;
      background-color: ${hexToRGB(theme.pallete.gray.c20, 0.1)};
    }

    &:focus {
      border-radius: 2px;
      position: relative;
      z-index: 1;
    }
  `,
  accordionItem: css`
    margin-top: ${isFirst ? '5px' : '0'};
  `,
})
